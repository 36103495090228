<template>
  <div class="gloal">
    <div class="table">
        <el-table
          ref="multipleTableRef"
          :data="dataSource.list"
          style="width: 100%; font-size: 13px;"
          @selection-change="handleSelectionChange"
          show-overflow-tooltip
      >
          <el-table-column property="user_image" label="头像" width="60">
            <template #default="scope">
              <div style="display: flex; align-items: center;">
                <div><el-avatar :src="scope.row.user_image" /></div>
              </div>
            </template>
          </el-table-column>
          <el-table-column property="user_name" label="昵称" show-overflow-tooltip width="160">
            <template #default="scope">
              {{ scope.row.user_name }}
            </template>
          </el-table-column>
          <el-table-column property="create_time" label="邮箱" show-overflow-tooltip width="180">
            <template #default="scope">
              <div style="white-space: nowrap; overflow: hidden; text-overflow:ellipsis;">{{ scope.row.user_email }}</div>
            </template>
          </el-table-column>
          <el-table-column label="空间使用" show-overflow-tooltip  width="150">
            <template #default="scope">
              {{ sizeFormat(scope.row.use_space) }} / {{ sizeFormat(scope.row.total_space) }}
            </template>
          </el-table-column>
          <el-table-column property="register_time" label="注册时间" show-overflow-tooltip  width="160">
            <template #default="scope">
              {{ format(scope.row.register_time) }}
            </template>
          </el-table-column>
          <el-table-column property="last_login_time" label="最近登录" show-overflow-tooltip  width="160">
            <template #default="scope">
              {{ format(scope.row.last_login_time) }}
            </template>
          </el-table-column>
          <el-table-column property="state" label="用户组" show-overflow-tooltip  width="80">
            <template #default="scope">
              <div style="color: #f56c6c;" v-if="scope.row.user_state == 0">禁用</div>
              <div style="color: #909399;" v-if="scope.row.user_state == 1">普通用户</div>
              <div style="color: #edbb71;" v-if="scope.row.user_state == 2">VIP用户</div>
              <div style="color: #67c23a;" v-if="scope.row.user_state == -1">管理员</div>
            </template>
          </el-table-column>
          <el-table-column label="操作" show-overflow-tooltip  width="200">
            <template #default="scope">
              <div style="display: flex; align-items: center;">
                  <div class="a-link" @click="tableDoSpace(scope.row)">分配空间</div>
                  <div v-if="scope.row.user_state != -1" style="margin: 0 5px; color: #dddee0;"> | </div>
                  <div class="a-link" @click="upUserLevel(scope.row.user_email)" style="color: #edbb71;" v-if="scope.row.user_state == 1">升级用户组</div>
                  <div style="margin: 0 5px; color: #dddee0;" v-if="scope.row.user_state == 1"> | </div>
                  <div class="a-link" @click="disabledUser(scope.row.user_email,1)" style="color: #67c23a;" v-if="scope.row.user_state == 0">启用</div>
                  <div class="a-link" @click="disabledUser(scope.row.user_email,0)" style="color: #f56c6c;" v-else-if="scope.row.user_state != -1">禁用</div>
              </div>
            </template>
          </el-table-column>
          
      
        </el-table>
    </div>
    <!-- 分页 -->
    <div class="pagination">
          <el-pagination
              v-if="dataSource.totalCount"
              :current-page="dataSource.nowPage"
              :page-size="dataSource.pageSize"
              :page-sizes="[15, 30, 50, 100]"
              small
              background
              layout="total, sizes, prev, pager, next, jumper"
              :total="dataSource.totalCount"
              @size-change="handlePageSizeChange"
              @current-change="handlePageNoChange"
              style="position: absolute; right: 10px; font-size: 13px;"
          />
    </div>
  </div>
</template>

<script>
import { size2Str } from '../Utils/SizeUtil'
import { formatDate } from '../Utils/FormatDate';
// import { ElMessage } from 'element-plus'

export default {
  name:'TableAdminUser',
  components: {
  },
  props:['dataSource'],
  setup(props,context) {

    // 空间格式化
    const sizeFormat = size2Str;
    // 时间格式化
    const format = formatDate;

    // 分页处理
    const handlePageSizeChange = (size) => {
      context.emit('changeSize',size);
    }
    const handlePageNoChange = (page) => {
      context.emit('changeNo',page);
    }

    // 空间操作
    const tableDoSpace = (item) => {
      context.emit('tableDoSpace',item);
    }

    // 升级用户组
    const upUserLevel = (email) => {
      context.emit('upUserLevel',email);
    }

    // 启用禁用用户
    const disabledUser = (email,type) => {
      context.emit('disabledUser',email,type);
    }

    return {
      sizeFormat,
      format,
      handlePageSizeChange,
      handlePageNoChange,
      tableDoSpace,
      upUserLevel,
      disabledUser,

    }
  },

}
</script>

<style scoped>
.gloal {
  position: relative;
  height: 70vh;
}

.pagination {
  position: absolute;
  width: 100%;
  bottom: 10px;
  padding-top: 10px;
  border-top: 1px solid #e0e0e0;
}
.text {
  font-size: 13px;
  cursor: pointer;
}

.text:hover {
  color: #06a7ff;
}

.hover-style {
  margin-right: 8px;
  font-size: 13px;
  color: #06a7ff;
  cursor: pointer;
}

.table {
  height: 66vh;
  overflow-y: scroll;
  overflow-x: hidden;
}

.text-too-long {
  max-width: 12vw; 
  white-space: nowrap; 
  overflow: hidden;
  text-overflow:ellipsis;
}

</style>