<template>
  <div></div>
</template>

<script>
import { useRouter,useRoute } from "vue-router";
import { useStore } from "vuex";
import { ElMessage } from 'element-plus'
import { gotoLogin } from '../Utils/gotoLogin';
import $ from "jquery";
import base_url from "@/Utils/BaseUrl";

export default {
  name:'QqCallback',
  components: {
  },
  setup() {

    // vuex
    const store = useStore();

    // vue-router
    const router = useRouter();
    const route = useRoute();

    const api = {
      logincallback: base_url + 'qq/loginCallback/',
      bindcallback: base_url + 'qq/bindCallback/',
    };

    const doQQLogin = () => {
      $.ajax({
        url: api.logincallback,
        type:'post',
        data:route.query,
        success(resp){
          ElMessage({
            showClose: true,
            message: resp.info,
            type: resp.status,
          })
          if(resp.status == 'success') {
            store.commit('updateUserInfo',resp.data.userInfo);
            localStorage.setItem('pantoken',resp.data.pantoken);
            let url = resp.data.url || '/';
            router.push(url);
          }
          else {
            router.push({name:'LoginView'});
          }
        }
      });
    }

    const doQQBind = () => {
      $.ajax({
        url: api.bindcallback,
        type:'post',
        data:route.query,
        headers: {
          pantoken: localStorage.getItem('pantoken'),
        },
        success(resp){
          ElMessage({
            showClose: true,
            message: resp.info,
            type: resp.status,
          })
          if(resp.status == 'success') {
            store.commit('doLoginByToken',function(){});
            let url = resp.data.url || '/';
            router.push(url);
          }
          else {
            router.push('/');
          }
          gotoLogin(router,resp);
        }
      });
    }

    if(route.query.state.charAt(0) == '1') doQQLogin();
    else if(route.query.state.charAt(0) == '2')  doQQBind();

    return {
      api,
      doQQLogin,
      doQQBind,

    }
  }

}
</script>

<style scoped>

</style>