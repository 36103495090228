<template>
  <div class="global">
    <div class="content">
      <div class="bar">
        <el-upload
            :show-file-list="false"
            :with-credentials="true"
            :multiple="true"
            :http-request="uploadFile"
            :accept="fileAccept"
            style="margin-right: 10px;"
          >
          <el-button ref="uploadBtnRef" type="primary" class="btn-style" >
            <span class="iconfont icon-upload"></span>上传
          </el-button>
        </el-upload>
        <el-button type="success" class="btn-style" v-if="pathEnd == 'all'" @click="createDirRef.DialogVisilble = true" >
          <span class="iconfont icon-folder-add"></span>新建文件夹
        </el-button>
        <el-button type="danger" class="btn-style" :disabled="isShowBtn" @click="deleteToRecycleRef.DialogVisilble = true" >
          <span class="iconfont icon-del"></span>批量删除
        </el-button>
        <el-button type="warning" class="btn-style" :disabled="isShowBtn" @click="moveFileRef.DialogVisilble = true" >
          <span class="iconfont icon-move"></span>批量移动
        </el-button>
        <el-input @input="searchFile" v-model="searchFileName" placeholder="输入文件名搜索" clearable class="input-style" />
        <div style="cursor: pointer;" @click="searchFile" ><el-icon :size="17" ><Search/></el-icon></div>
      </div>
      <div class="body">
        <DirShow :dir="dir" @changeDir="changeDir"></DirShow>
      </div>
      <div v-if="dataSource.totalCount" >
        <TableBlock :dir="dir" :dataSource="dataSource"
         @changeSize="changeSize" @changeNo="changeNo" 
         @changeDir="changeDir"  @updateSelection="updateSelection"
         @moveOneFile="moveOneFile" @doReNameFile="doReNameFile" 
         @delOneFile="delOneFile" @doCraeteShareLink="doCraeteShareLink" @toDownload="toDownload" >
        </TableBlock>
      </div>
      <div v-else>
        <NoData :msg="noDataMsg">
          <template v-slot:forMid>
            <div style="height: 8vh;"></div>
          </template>
          <template v-slot:forDoBtn>
            <div style="display: flex; justify-content: center;">
              <div v-if="pathEnd == 'all'" class="no-data-item" @click="createDirRef.DialogVisilble = true">
                <div>
                  <div><img src="../../assets/icon-image/folder.png" alt="新建目录" style="width: 5vw;"></div>
                  <div>新建目录</div>
                </div>
              </div>
              <div v-if="pathEnd == 'all'" style="width: 30px; flex: 1;"></div>
              <div class="no-data-item">
                <el-upload
                    :show-file-list="false"
                    :with-credentials="true"
                    :multiple="true"
                    :http-request="uploadFile"
                    :accept="fileAccept"
                  >
                  <div>
                    <div><img src="../../assets/icon-image/file.png" alt="上传文件" style="width: 5vw;"></div>
                    <div>上传文件</div>
                  </div>
                </el-upload>
              </div>
            </div>
          </template>
        </NoData>
      </div>
    </div>
  </div>

  <CreateDir ref="createDirRef" @mkDir="mkDir" ></CreateDir>
  <MoveFile ref="moveFileRef" @doFileMove="doFileMove" ></MoveFile>
  <TipsBlock ref="deleteToRecycleRef" :msg="deleteToRecycleMsg" @confirmReCall="doDelToRecycle" ></TipsBlock>
  <ShareDialog ref="shareDialogRef" ></ShareDialog>
  <DownloadBlock ref="downloadBlockRef" @confirmReCall="doDownloadFile" ></DownloadBlock>

</template>

<script>
import TableBlock from "../../components/TableBlock";
import { ref,watch,computed } from 'vue';
import $ from "jquery";
import { ElMessage } from 'element-plus'
import { useRoute,useRouter } from 'vue-router'
import { gotoLogin } from '../../Utils/gotoLogin';
import CreateDir from '../../components/CreateDir';
import FileAccept from '../../Utils/FileAccept'
import NoData from '../../components/NoData';
import DirShow from "../../components/DirShow";
import { useStore } from "vuex";
import MoveFile from '../../components/MoveFile'
import TipsBlock from '../../components/TipsBlock'
import ShareDialog from '../../components/ShareDialog'
import DownloadBlock from '../../components/DownlaodBlock'
import base_url from '../../Utils/BaseUrl'

export default {
  name:'HomeBlock',
  components: {
    TableBlock,
    CreateDir,
    NoData,
    DirShow,
    MoveFile,
    TipsBlock,
    ShareDialog,
    DownloadBlock,
  },
  setup(props,context) {
    // vuex
    const store = useStore();
    // vue-router
    const router = useRouter();
    // vue-route
    const route = useRoute();
    const pathBegin = ref('');
    const pathEnd = ref('');

    // 后端api
    const api = {
      getFileList: base_url + 'file/getAllFile/',
      mkdir:base_url + 'file/mkDir/',
      moveFile:base_url + 'file/move/',
      reName:base_url + 'file/reName/',
      delFile:base_url + 'file/remove/toRecycle/',
      download:base_url + 'file/download/',
    }

    // 表格信息
    const dataSource = ref({
      list:[],
      totalCount:0,
      nowPage:1,
      pageSize:15,
    });
    // 当前目录
    const dir = ref('/');
    // 修改目录
    const changeDir = (new_dir) => {
      dir.value = new_dir;
      doGetFileList();
    }
    // 搜索文件
    const searchFileName = ref('');
    const searchFile = () => {
      doGetFileList();
    }
    // 获取文件列表
    const getFileList = (type, nowPage, pageSize, dir) => {
      $.ajax({
        url: api.getFileList,
        type:'get',
        data:{
          type: type,
          nowPage:nowPage,
          pageSize:pageSize,
          dir:dir,
          keyWord:searchFileName.value,
        },
        headers: {
          pantoken: localStorage.getItem('pantoken'),
        },
        success(resp){
          if(resp.status == 'success') {
            dataSource.value = resp.data;
          } else {
            ElMessage({
              showClose: true,
              message: resp.info,
              type: resp.status,
            })
          }
          gotoLogin(router,resp);
        }
      });
    }
    const doGetFileList = () => {
      dataSource.value.list = [];
      getFileList(pathEnd.value,dataSource.value.nowPage,dataSource.value.pageSize,dir.value);
      // 更新网盘空间
      store.dispatch('doUpdateSpaceByToken',function(resp) {
        if(resp.status != 'success') {
          ElMessage({
            showClose: true,
            message: resp.info,
            type: resp.status,
          })
          router.push({name:'LoginView'});
        }
      })
    }
    const changeSize = (size) => {
      dataSource.value.pageSize = size;
      doGetFileList();
    }
    const changeNo = (page) => {
      dataSource.value.nowPage = page;
      doGetFileList();
    }

    // 路由切换
    watch(
      () => route.fullPath,
      (newVal) => {
        let index = newVal.lastIndexOf("/");
        pathBegin.value = newVal.substr(0,index);
        pathEnd.value = newVal.substr(index+1);
        if(pathBegin.value == "/home") {
          if(pathEnd.value != "all") dir.value = "/";
          doGetFileList();
        }
      },
      { immediate: true, deep: true }
    );

    // 新建目录
    const createDirRef = ref();
    const mkDir = (new_dir_name) => {
      $.ajax({
        url: api.mkdir,
        type:'post',
        data:{
          new_dir_name:new_dir_name,
          dir:dir.value,
        },
        headers: {
          pantoken: localStorage.getItem('pantoken'),
        },
        success(resp){
          if(resp.status == 'success') {
            doGetFileList();
            createDirRef.value.DialogVisilble = false;
          } 
          else {
            ElMessage({
              showClose: true,
              message: resp.info,
              type: resp.status,
            })
          }
          gotoLogin(router,resp);
        }
      });
    }

    // 文件上传约束
    const fileAccept = computed(() => {
      const categoryItem = FileAccept[pathEnd.value];
      return categoryItem ? categoryItem.accept : "*";
    });

    // 文件上传
    const uploadFile = async (fileData) => {
      await context.emit('uploadFile',fileData.file,dir.value);
    }

    // 没有数据信息
    const noDataMsg = ref('没有该类型的文件，上传第一个文件吧');
    const uploadBtnRef = ref(null);

    // 移动与删除展示
    const isShowBtn = ref(true);
    const changeShow = (show) => {
      isShowBtn.value = show;
    }

    const moveFileRef = ref();
    
    // 选中处理
    const selectedList = ref([]);
    let selectedIdList = computed(() => {
      let arr = [];
      for(let i=0;i<selectedList.value.length;i++) arr.push(selectedList.value[i].file_id);
      return arr;
    });
    const updateSelection = (selection) => {
      selectedList.value = selection;
      changeShow(selectedList.value.length == 0);
    }

    
    // 移动文件
    const doFileMove = (aim_dir) => {
      $.ajax({
        url: api.moveFile,
        type:'post',
        data:{
          aim_dir: aim_dir,
          files:selectedIdList.value,
        },
        headers: {
          pantoken: localStorage.getItem('pantoken'),
        },
        success(resp){
          ElMessage({
            showClose: true,
            message: resp.info,
            type: resp.status,
          })
          if(resp.status == 'success') {
            moveFileRef.value.DialogVisilble = false;
            // 清空
            selectedList.value.length = 0;
            doGetFileList();
          }
          gotoLogin(router,resp);
        }
      });
    }
    // 移动单个文件
    const moveOneFile = (item) => {
      // 清空
      selectedList.value.length = 0;
      selectedList.value.push(item);
      moveFileRef.value.DialogVisilble = true;
    }

    // 重命名文件
    const doReNameFile = (item) => {
      // 校验参数
      if(!item.file_name || item.file_name.length == 0) {
        ElMessage({
          showClose: true,
          message: "文件名不能为空",
          type: "warning",
        })
        doGetFileList();
        return;
      }
      if(item.file_name.indexOf('/') != -1) {
        ElMessage({
          showClose: true,
          message: "文件名不能含有 /",
          type: "warning",
        })
        doGetFileList();
        return;
      }
      $.ajax({
        url: api.reName,
        type:'post',
        data:{
          dir: dir.value,
          file_id:item.file_id,
          new_name:item.file_name,
        },
        headers: {
          pantoken: localStorage.getItem('pantoken'),
        },
        success(resp){
          if(resp.code != 601) {
            ElMessage({
              showClose: true,
              message: resp.info,
              type: resp.status,
            })
          }
          doGetFileList();
          gotoLogin(router,resp);
        }
      });
    }

    // 删除文件到回收站
    const deleteToRecycleRef = ref();
    const deleteToRecycleMsg = ref('确定要删除这些文件吗？删除后可在回收站内查看或还原');
    const doDelToRecycle = () => {
      $.ajax({
        url: api.delFile,
        type:'post',
        data:{
          files:selectedIdList.value,
        },
        headers: {
          pantoken: localStorage.getItem('pantoken'),
        },
        success(resp){
          ElMessage({
            showClose: true,
            message: resp.info,
            type: resp.status,
          })
          if(resp.status == 'success') {
            deleteToRecycleRef.value.DialogVisilble = false;
            // 清空
            selectedList.value.length = 0;
            doGetFileList();
          }
          gotoLogin(router,resp);
        }
      });
    }
    // 删除单个文件
    const delOneFile = (item) => {
      // 清空
      selectedList.value.length = 0;
      selectedList.value.push(item);
      deleteToRecycleRef.value.DialogVisilble = true;
    }

    // 创建分享链接
    const shareDialogRef = ref();
    const doCraeteShareLink = (item) => {
      shareDialogRef.value.shareItem.file_id = item.file_id;
      shareDialogRef.value.shareItem.file_name = item.file_name;
      shareDialogRef.value.DialogVisilble = true;
    }

    // 下载文件
    const downloadBlockRef = ref();
    const doDownloadFile = (file_id) => {
      $.ajax({
        url: api.download,
        type:'get',
        data:{
          file_id:file_id,
        },
        headers: {
          pantoken: localStorage.getItem('pantoken'),
        },
        success(resp){
          if(resp.status == 'success') {
            downloadBlockRef.value.DialogVisilble = false;
            // 下载
            const a = document.createElement('a')
            a.href = resp.data.url
            // a.download = res.success.fileName // 下载后文件名
            a.style.display = 'none'
            document.body.appendChild(a);
            a.click() // 点击下载
            document.body.removeChild(a) // 下载完成移除元素
          }
          gotoLogin(router,resp);
        }
      });
    }
    const toDownload = (item) => {
      downloadBlockRef.value.file_name = item.file_name;
      downloadBlockRef.value.file_id = item.file_id;
      downloadBlockRef.value.DialogVisilble = true;
    }



    return {
      dataSource,
      searchFileName,
      searchFile,
      getFileList,
      dir,
      pathEnd,
      mkDir,
      createDirRef,
      fileAccept,
      uploadFile,
      doGetFileList,
      changeSize,
      changeNo,
      noDataMsg,
      uploadBtnRef,
      changeDir,
      isShowBtn,
      changeShow,
      moveFileRef,
      updateSelection,
      doFileMove,
      moveOneFile,
      doReNameFile,
      deleteToRecycleRef,
      deleteToRecycleMsg,
      doDelToRecycle,
      delOneFile,
      doCraeteShareLink,
      shareDialogRef,
      doDownloadFile,
      downloadBlockRef,
      toDownload,

    }
  }

}
</script>

<style scoped>
.btn-style{
  font-size: 12px;
  height: 30px;
}

.global{
  margin: 0 15px;
  width: calc(75vw);
}

.bar {
  display: flex;
  align-items: center;
  margin: 15px 0;
  margin-bottom: 10px;
}

.iconfont{
  font-size: 13px;
}

.input-style {
  width: 25vw;
  margin: 0 8px;
  margin-left: 15px;
  font-size: 13px;
}

.body {
  font-size: 13px;
  font-weight: bold;
  margin-bottom: 5px;
}

.no-data-item {
  background-color: #f1f1f1;
  width: 8vw;
  height: 20vh;
  margin-top: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 13px;
  cursor: pointer;
}

</style>