<template>
  <div class="global">
    <div class="content">
      <div class="bar">
        <el-button type="danger" class="btn-style" :disabled="isShowBtn" @click="delShareRef.DialogVisilble = true" >
          <span class="iconfont icon-del"></span>批量删除
        </el-button>
        <el-input @input="searchFile" v-model="searchFileName" placeholder="输入文件名搜索" clearable class="input-style" />
        <div style="cursor: pointer;" @click="searchFile" ><el-icon :size="17" ><Search/></el-icon></div>
      </div>
      <div class="body">
        <DirShow :dir="dir" @changeDir="changeDir"></DirShow>
      </div>
      <div v-if="dataSource.totalCount" >
        <TableAdminFile :dataSource="dataSource"
         @changeSize="changeSize" @changeNo="changeNo" 
         @updateSelection="updateSelection" @delOneShare="delOneShare" >
        </TableAdminFile>
      </div>
      <div v-else>
        <NoData :msg="noDataMsg">
          <template v-slot:forMid>
            <div style="height: 15vh;"></div>
          </template>
        </NoData>
      </div>
    </div>
  </div>

  <TipsBlock ref="delShareRef" :msg="delMsg" @confirmReCall="doDelShares" ></TipsBlock>

</template>

<script>
import { ref,computed } from 'vue';
import $ from "jquery";
import { ElMessage } from 'element-plus'
import { useRouter } from 'vue-router'
import { gotoLogin } from '../../Utils/gotoLogin';
import TableAdminFile from '../../components/TableAdminFile'
import NoData from '../../components/NoData';
import DirShow from "../../components/DirShow";
import TipsBlock from '../../components/TipsBlock'
import base_url from '../../Utils/BaseUrl'

export default {
  name:'AdminFile',
  components: {
    TableAdminFile,
    NoData,
    DirShow,
    TipsBlock,
  },
  setup() {

    // vue-router
    const router = useRouter();

    const dir = ref('/');
    const noDataMsg = ref('暂无相关数据');

    // 后端api
    const api = {
      get_all_share_file:base_url + 'admin/getAllShareFile/',
      del_files:base_url + 'admin/removeShares/',
    }

    // 搜索文件
    const searchFileName = ref('');
    const searchFile = () => {
      doAdminGetFileList();
    }

    // 表格信息
    const dataSource = ref({
      list:[],
      totalCount:0,
      nowPage:1,
      pageSize:15,
    });

    // 管理员获取所有文件列表
    const adminGetList = (nowPage, pageSize) => {
      $.ajax({
        url: api.get_all_share_file,
        type:'get',
        data:{
          nowPage:nowPage,
          pageSize:pageSize,
          keyWord:searchFileName.value,
        },
        headers: {
          pantoken: localStorage.getItem('pantoken'),
        },
        success(resp){
          if(resp.status == 'success') {
            dataSource.value = resp.data;
          } else {
            ElMessage({
              showClose: true,
              message: resp.info,
              type: resp.status,
            })
          }
          gotoLogin(router,resp);
        }
      });
    }
    const doAdminGetFileList = () => {
      dataSource.value.list = [];
      adminGetList(dataSource.value.nowPage,dataSource.value.pageSize);
    }
    doAdminGetFileList();
    const changeSize = (size) => {
      dataSource.value.pageSize = size;
      doAdminGetFileList();
    }
    const changeNo = (page) => {
      dataSource.value.nowPage = page;
      doAdminGetFileList();
    }

    // 移动与删除展示
    const isShowBtn = ref(true);
    const changeShow = (show) => {
      isShowBtn.value = show;
    }
    
    // 选中处理
    const selectedList = ref([]);
    let selectedIdList = computed(() => {
      let arr = [];
      for(let i=0;i<selectedList.value.length;i++) arr.push(selectedList.value[i].share_id);
      return arr;
    });
    const updateSelection = (selection) => {
      selectedList.value = selection;
      changeShow(selectedList.value.length == 0);
    }

    // 删除分享
    const delShareRef = ref();
    const delMsg = ref('确定要删除这些分享吗');
    const doDelShares = () => {
        $.ajax({
        url: api.del_files,
        type:'post',
        data:{
          files:selectedIdList.value,
        },
        headers: {
          pantoken: localStorage.getItem('pantoken'),
        },
        success(resp){
          ElMessage({
            showClose: true,
            message: resp.info,
            type: resp.status,
          })
          if(resp.status == 'success') {
            delShareRef.value.DialogVisilble = false;
            // 清空
            selectedList.value.length = 0;
            doAdminGetFileList();
          }
          gotoLogin(router,resp);
        }
      });
    }
    const delOneShare = (item) => {
      // 清空
      selectedList.value.length = 0;
      selectedList.value.push(item);
      delShareRef.value.DialogVisilble = true;
    }

    return {
      isShowBtn,
      selectedList,
      selectedIdList,
      updateSelection,
      dir,
      noDataMsg,
      searchFileName,
      searchFile,
      adminGetList,
      changeSize,
      changeNo,
      dataSource,
      delShareRef,
      doDelShares,
      delOneShare,
      delMsg,



    }
  }

}
</script>

<style scoped>
.btn-style{
  font-size: 12px;
  height: 30px;
}

.global{
  margin: 0 15px;
  width: calc(75vw);
}

.bar {
  display: flex;
  align-items: center;
  margin: 15px 0;
  margin-bottom: 10px;
}

.iconfont{
  font-size: 13px;
}

.input-style {
  width: 25vw;
  margin: 0 8px;
  margin-left: 15px;
  font-size: 13px;
}

.body {
  font-size: 13px;
  font-weight: bold;
  margin-bottom: 5px;
}

</style>