<template>
  <div class="global">
    <div class="content">
      <div class="bar">
        <el-button type="primary" class="btn-style" :disabled="isShowBtn" @click="cancelShareBlock.DialogVisilble = true" >
          <span class="iconfont icon-cancel"></span>取消分享
        </el-button>
      </div>
      <div class="body">
        <DirShow :dir="dir" ></DirShow>
      </div>
      <div v-if="dataSource.totalCount" >
        <TableShare :dataSource="dataSource"
          @changeSize="changeSize" @changeNo="changeNo" 
          @updateSelection="updateSelection"
          @doOneCancelShare="doOneCancelShare" >
        </TableShare>
      </div>
      <div v-else>
        <NoData :msg="noDataMsg">
          <template v-slot:forMid>
            <div style="height: 15vh;"></div>
          </template>
        </NoData>
      </div>
    </div>
  </div>

  <TipsBlock ref="cancelShareBlock" :msg="cancelShareMsg" @confirmReCall="doCancelShareLink" ></TipsBlock>

</template>

<script>
import TableShare from "../../components/TableShare";
import { ref,computed } from 'vue';
import $ from "jquery";
import { ElMessage } from 'element-plus'
import { useRouter } from 'vue-router'
import { gotoLogin } from '../../Utils/gotoLogin';
import NoData from '../../components/NoData';
import DirShow from "../../components/DirShow";
import TipsBlock from '../../components/TipsBlock'
import base_url from '../../Utils/BaseUrl'

export default {
  name:'ShareBlock',
  components: {
    TableShare,
    NoData,
    DirShow,
    TipsBlock,
  },
  setup() {
    // vue-router
    const router = useRouter();

    const noDataMsg = ref('暂无相关数据');
    const cancelShareMsg = ref('确定要取消分享这些文件吗？');

    const dir = ref('/');

    const api = {
      get_share_list: base_url + 'share/getAll/',
      do_cancel:base_url + 'share/cancelLink/',
    }

    // 表格信息
    const dataSource = ref({
      list:[],
      totalCount:0,
      nowPage:1,
      pageSize:15,
    });
    // 获取分享列表
    const getShareFileList = (nowPage, pageSize) => {
      $.ajax({
        url: api.get_share_list,
        type:'get',
        data:{
          nowPage:nowPage,
          pageSize:pageSize,
        },
        headers: {
          pantoken: localStorage.getItem('pantoken'),
        },
        success(resp){
          if(resp.status == 'success') {
            dataSource.value = resp.data;
          } else {
            ElMessage({
              showClose: true,
              message: resp.info,
              type: resp.status,
            })
          }
          gotoLogin(router,resp);
        }
      });
    }
    const doGetShareFileList = () => {
      dataSource.value.list = [];
      getShareFileList(dataSource.value.nowPage,dataSource.value.pageSize);
    }
    doGetShareFileList();
    const changeSize = (size) => {
      dataSource.value.pageSize = size;
      doGetShareFileList();
    }
    const changeNo = (page) => {
      dataSource.value.nowPage = page;
      doGetShareFileList();
    }

    // 取消分享展示
    const isShowBtn = ref(true);
    const changeShow = (show) => {
      isShowBtn.value = show;
    }
    const cancelShareBlock = ref();

    // 选中处理
    const selectedList = ref([]);
    let selectedIdList = computed(() => {
      let arr = [];
      for(let i=0;i<selectedList.value.length;i++) arr.push(selectedList.value[i].share_id);
      return arr;
    });
    const updateSelection = (selection) => {
      selectedList.value = selection;
      changeShow(selectedList.value.length == 0);
    }

    // 取消分享
    const doCancelShareLink = () => {
      $.ajax({
        url: api.do_cancel,
        type:'post',
        data:{
          shares:selectedIdList.value,
        },
        headers: {
          pantoken: localStorage.getItem('pantoken'),
        },
        success(resp){
          ElMessage({
            showClose: true,
            message: resp.info,
            type: resp.status,
          })
          if(resp.status == 'success') {
            // 清空
            selectedList.value.length = 0;
          }
          doGetShareFileList();
          cancelShareBlock.value.DialogVisilble = false;
          gotoLogin(router,resp);
        }
      });
    }
    const doOneCancelShare = (item) => {
      // 清空
      selectedList.value.length = 0;
      selectedList.value.push(item);
      cancelShareBlock.value.DialogVisilble = true;
    }


    return {
      noDataMsg,
      selectedList,
      selectedIdList,
      updateSelection,
      changeSize,
      changeNo,
      dataSource,
      doGetShareFileList,
      isShowBtn,
      changeShow,
      dir,
      cancelShareMsg,
      cancelShareBlock,
      doCancelShareLink,
      doOneCancelShare,


    }
  }

}
</script>

<style scoped>
.btn-style{
  font-size: 12px;
  height: 30px;
}

.global{
  margin: 0 15px;
  width: calc(75vw);
}

.bar {
  display: flex;
  align-items: center;
  margin: 15px 0;
  margin-bottom: 10px;
}

.iconfont{
  font-size: 13px;
}

.body {
  font-size: 13px;
  font-weight: bold;
  margin-bottom: 5px;
}

</style>