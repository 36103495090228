<template>
  <div class="global">
    <div style="display: flex; align-items: center; margin-top: -180px;">
      <img src="../assets/提示.png" alt="提示" style="height: 35px;">
      <div class="text">{{ err_msg }}</div>
    </div>
  </div>
</template>

<script>
import { ref } from 'vue';

export default {
  name:'NotFoundPage',
  components: {
  },
  setup() {

    // 错误信息
    const err_msg = ref('抱歉，找不到页面');

    return {
      err_msg,


    }
  }

}
</script>

<style scoped>
.global {
  height: calc(100vh);
  background: url('../assets/share_bg.png');
  background-repeat: repeat-x;
  background-position: 0 bottom;
  background-color: #eef2f6;
  display: flex;
  align-items: center;
  justify-content: center;
}

.text {
  font-size: 22px;
  margin-left: 10px;
  color: #bfbfbf;
}

</style>