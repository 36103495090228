<template>
  <el-dialog
    v-model="DialogVisilble"
    :before-close="closeDialog"
    title="下载文件"
    width="40%"
  >
    <div style="margin-top: -15px;"></div>
    <div class="item-class">
      <div class="row-class">文件名：{{ file_name }}</div>
      <div class="row-class" v-if="store.state.is_login == false">
        <div class="common-class">用户组：未登录</div>
        <div class="common-text">*未登录用户下载限速100kB/s，登录后升级VIP享受极速下载</div>
      </div>
      <div class="row-class" v-if="store.state.is_login == true && store.state.userInfo.user_state == 1">
        <div class="common-class">用户组：普通用户</div>
        <div class="common-text">*普通用户下载限速100kB/s，升级VIP享受极速下载</div>
      </div>
      <div class="row-class" v-if="store.state.userInfo.user_state == 2">
        <div class="vip-class">用户组：VIP用户</div>
        <div class="vip-text">*极速下载权限生效中</div>
      </div>
      <div class="row-class" v-if="store.state.userInfo.user_state == -1">
        <div class="admin-class">用户组：管理员</div>
        <div class="admin-text">*极速下载权限生效中</div>
      </div>
    </div>
    <div style="margin-bottom: 15px;"></div>
    <div style="display: flex; border-top: 1px solid #f1f2f4;">
      <div style="flex: 1;"></div>
      <el-button type="primary" @click="doDownload" style="margin-bottom: -15px; margin-top: 10px;">
        下载
      </el-button>
    </div>
  </el-dialog>
</template>

<script>
import { ref } from 'vue';
import { useStore } from "vuex";

export default {
  name:'DownloadBlock',
  components: {
  },
  props:[],
  setup(props,context) {
    
    // vuex
    const store = useStore();
    // 控制Dialog显示
    const DialogVisilble = ref(false);
    const file_name = ref('');
    const file_id = ref('');

    const doDownload = () => {
      context.emit('confirmReCall',file_id.value);
    }

    // 关闭
    const closeDialog = (done) => {
      file_name.value = '';
      file_id.value = '';
      done();
    }


    return {
      DialogVisilble,
      doDownload,
      file_name,
      store,
      file_id,
      closeDialog,
    }
  }

}
</script>

<style scoped>
.item-class {
  font-size: 13px;
  white-space: nowrap; 
  overflow: hidden;
  text-overflow:ellipsis;
}
.row-class {
  padding: 0 10px;
  margin: 10px 0;
}

.commom-class {
  color: #909399
}

.vip-class {
  color: #e6a23c;
}

.admin-class {
  color: #f56c6c;
}

.common-text {
  margin-top: 3px;
  font-size: 12px;
  color: #b1b3b8;
}

.vip-text {
  margin-top: 3px;
  font-size: 12px;
  color: #eebe77;
}

.admin-text {
  margin-top: 3px;
  color: #f89898;
  font-size: 12px;
}



</style>