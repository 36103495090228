import { createRouter, createWebHistory } from 'vue-router'

import LoginView from '../views/LoginView'
import FrameWork from '../views/FrameWork'
import HomeBlock from '../views/Home/HomeBlock'
import ShareBlock from '../views/Share/ShareBlock';
import RecycleBlock from '../views/Recycle/RecycleBlock';
import WebShareBlock from '../views/WebShare/WebShareBlock'
import ShareCheckBlock from '../views/WebShare/ShareCheckBlock'
import ShareNotFound from '../views/WebShare/ShareNotFound'
import QqCallback from '../views/QqCallback'
import GithubCallback  from '../views/GithubCallback'
import AdminFile from '../views/Admin/AdminFile'
import AdminUser from '../views/Admin/AdminUser'
import AdminActive from '../views/Admin/AdminActive'
import NotFoundPage from "../views/NotFoundPage";

import { ElMessage } from 'element-plus'
import store from "../store/index";

const routes = [
  {
    path: '/',
    name: 'index',
    component: FrameWork,
    meta:{
      need_login:true,
    },
    children: [
      {
        path: '/',
        redirect: "/home/all",
        meta:{
          need_login:true,
        },
      },
      {
        path: '/home/:category',
        name: '首页',
        component: HomeBlock,
        meta:{
          need_login:true,
        },
      },
      {
        path: '/myshare',
        name: '我的分享',
        component: ShareBlock,
        meta:{
          need_login:true,
        },
      },
      {
        path: '/recycle',
        name: '回收站',
        component: RecycleBlock,
        meta:{
          need_login:true,
        },
      },
      {
        path: '/settings/file',
        name: '管理员文件',
        component: AdminFile,
        meta:{
          need_login:true,
        },
      },
      {
        path: '/settings/user',
        name: '管理员用户',
        component: AdminUser,
        meta:{
          need_login:true,
        },
      },
      {
        path: '/settings/activationCode',
        name: '管理员激活',
        component: AdminActive,
        meta:{
          need_login:true,
        },
      },
    ],
  },
  {
    path: '/login',
    name: 'LoginView',
    component: LoginView,
    meta:{
      need_login:false,
    },
  },
  {
    path: '/share/:shareId',
    name: 'WebShareBlock',
    component: WebShareBlock,
    meta:{
      need_login:false,
    },
  },
  {
    path: '/shareCheck/:shareId',
    name: 'ShareCheckBlock',
    component: ShareCheckBlock,
    meta:{
      need_login:false,
    },
  },
  {
    path: '/share/notfound',
    name: 'ShareNotFound',
    component: ShareNotFound,
    meta:{
      need_login:false,
    },
  },
  {
    path: '/qq/callback',
    name: 'QqCallback',
    component: QqCallback,
    meta:{
      need_login:false,
    },
  },
  {
    path: '/github/callback',
    name: 'GithubCallback',
    component: GithubCallback,
    meta:{
      need_login:false,
    },
  },
  {
    path: '/404',
    name: 'NotFound',
    component: NotFoundPage,
    meta:{
      need_login:false,
    },
  },
  {
    path: '/:catchAll(.*)',
    redirect: '/404',
    meta:{
      need_login:false,
    },
  },

]

const router = createRouter({
  history: createWebHistory(),
  routes
})

router.beforeEach((to, from, next) => {
  if(from.name != 'LoginView' && to.name == 'LoginView' 
    && from.name != 'QqCallback' && from.meta.need_login == true) {
    if(!(store.state.aim_path && store.state.aim_path != '')) store.state.aim_path = from.fullPath;
    next();
  }
  if(to.meta.need_login == true && store.state.is_login == false) {
    if(localStorage.getItem('pantoken')) {
      store.dispatch('doLoginByToken',function(resp) {
        if(resp.status != 'success') {
          ElMessage({
            showClose: true,
            message: resp.info,
            type: resp.status,
          })
          localStorage.removeItem('pantoken');
          store.state.aim_path = to.fullPath;
          router.push({name:'LoginView'});
        }
        else next();
      });
    }
    else {
      store.state.aim_path = to.fullPath;
      router.push({name:'LoginView'});
    }
  }
  else next();
})

export default router
