import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

const app = createApp(App);

// 引入element UI
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import zhCn from 'element-plus/es/locale/lang/zh-cn'  // 中文

// 解决 ElTable 自动宽度高度导致的「ResizeObserver loop limit exceeded」问题
const debounce = (fn, delay) => {
  let timer = null;
  return function () {
    let context = this;
    let args = arguments;
    clearTimeout(timer);
    timer = setTimeout(function () {
      fn.apply(context, args);
    }, delay);
  }
}

const _ResizeObserver = window.ResizeObserver;
window.ResizeObserver = class ResizeObserver extends _ResizeObserver{
  constructor(callback) {
    callback = debounce(callback, 16);
    super(callback);
  }
}

app.use(ElementPlus, {
  locale: zhCn,
});

app.config.globalProperties.$ELEMENT = { size: 'small' };

// 引入element icon
import * as ElementPlusIconsVue from '@element-plus/icons-vue'
for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
  app.component(key, component)
}

// 引入iconfont icon
import './assets/icon-admin/iconfont.css';
import './assets/icon/iconfont.css';

app.use(store);
app.use(router);
app.mount('#app');

// 引入全局样式
import './assets/base.css'

// jQuery配置Ajax全局响应拦截，进行数据解密
// jQuery配置全局Loading...效果
import $ from 'jquery';
import { Base64 } from './Utils/DESUtil';
import Loading from './Utils/Loading'


let loading = null;

$.ajaxSetup({
  processData: true,
  beforeSend: function() { // 发送请求前执行的方法
    loading = Loading();
  },
  // 携带cookie
  xhrFields:{
    withCredentials: true,
  },
  dataFilter(resp) {
    resp = JSON.parse(resp);
    let data = resp.data;
    if(data) resp.data = JSON.parse(Base64.decode(resp.data));
    return JSON.stringify(resp);
  },
  complete: function() { // 发送请求完成后执行的方法
    loading.close();
  }
});



// 用于去掉eazyplayer警告,开发时禁用，打包开启
app.config.warnHandler = () => {}
