<template>
  <el-dialog
    v-model="DialogVisilble"
    :before-close="closeDialog"
    title="分配空间"
    width="30%"
  >
    <div style="margin-top: -15px;"></div>
    <div class="item-class">
      <div style="margin-bottom: 15px; margin-top: 5px;">昵称：{{ selected_user.user_name }}</div>
      <div style="margin: 10px 0;">空间使用：{{ size2Str(selected_user.use_space) }} / {{ size2Str(selected_user.total_space) }}</div>
      <div style="margin: 10px 0;">
        <el-form-item label="空间操作：" class="form-class">
          <el-radio-group v-model="spaceType">
            <el-radio :label="1" size="small">增加</el-radio>
            <el-radio :label="-1" size="small">减少</el-radio>
          </el-radio-group>
        </el-form-item>
      </div>
      <div style="display: flex; align-items: center; margin: 10px 0;">
        <div>空间大小：</div>
        <div><el-input-number v-model="spaceSize" :min="1" :max="10" /></div>
        <div style="margin-left: 5px;">GB</div>
      </div>
    </div>
    <div class="errInfo"> {{ err_msg }} </div>
    <div style="margin-bottom: 15px;"></div>
    <div style="display: flex; border-top: 1px solid #f1f2f4;">
      <div style="flex: 1;"></div>
      <el-button type="primary" @click="doAllocateSpace" style="margin-bottom: -15px; margin-top: 10px;">
        确定
      </el-button>
    </div>
  </el-dialog>
</template>

<script>
import { size2Str } from '../Utils/SizeUtil'
import { ref } from 'vue'
import { useRouter } from "vue-router";
import { ElMessage } from 'element-plus'
import $ from "jquery";
import { gotoLogin } from '../Utils/gotoLogin';
import base_url from '../Utils/BaseUrl'

export default {
  name:'AllocateSpace',
  components: {
  },
  setup() {

    // vue-router
    const router = useRouter();

    const api = {
      modify_space:base_url + 'admin/modifySpace/',
    }

    // 控制Dialog显示
    const DialogVisilble = ref(false);
    const err_msg = ref('');

    const selected_user = ref({});
    const spaceType = ref(0)
    const spaceSize = ref(1);

    const doAllocateSpace = () => {
      err_msg.value = '';
      // 校验参数
      if(spaceType.value != 1 && spaceType.value != -1) {
        err_msg.value = '请选择空间操作'
        return;
      }
      if(spaceSize.value < 1 || spaceSize.value > 10) {
        err_msg.value = '请选择空间大小'
        return;
      }
      $.ajax({
        url: api.modify_space,
        type:'post',
        data: {
          email:selected_user.value.user_email,
          type:spaceType.value,
          size:spaceSize.value,
        },
        headers: {
          pantoken: localStorage.getItem('pantoken'),
        },
        success(resp){
          ElMessage({
            showClose: true,
            message: resp.info,
            type: resp.status,
          })
          if(resp.status == 'success') {
            DialogVisilble.value = false;
            err_msg.value = '';
            selected_user.value = {};
            spaceType.value = 0;
            spaceSize.value = 1;
          }
          gotoLogin(router,resp);
        }
      });
    };


    // 关闭
    const closeDialog = (done) => {
      err_msg.value = '';
      selected_user.value = {};
      spaceType.value = 0;
      spaceSize.value = 1;
      done();
    }

    return {
      DialogVisilble,
      err_msg,
      size2Str,
      selected_user,
      spaceType,
      spaceSize,
      closeDialog,
      doAllocateSpace,


    }
  }

}
</script>

<style scoped>
.item-class {
  font-size: 13px;
  color: #606266;
}

.errInfo{
  font-size: 12px;
  color: rgb(234, 80, 80);
  margin-top: 5px;
}
</style>
