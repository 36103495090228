<template>
  <div class="global">
    <div style="display: flex; align-items: center; margin-top: -180px;">
      <img src="../../assets/提示.png" alt="提示" style="height: 35px;">
      <div class="text">{{ err_msg }}</div>
    </div>
  </div>
</template>

<script>
import { useRoute } from "vue-router";
import { ref } from 'vue';

export default {
  name:'ShareNotFound',
  components: {
  },
  setup() {

    // route
    const route = useRoute();

    const err_code = ref(route.query.code);

    // 错误信息展示对象
    const err_show = {
      602:"链接不存在或已取消",
      603:"来晚啦，链接已失效",
      604:"分享用户不存在",
      605:"分享文件不存在",
      606:"分享用户已被封禁",
    }

    // 错误信息
    const err_msg = err_show[err_code.value];

    return {
      route,
      err_code,
      err_show,
      err_msg,


    }
  }

}
</script>

<style scoped>
.global {
  height: calc(100vh);
  background: url('../../assets/share_bg.png');
  background-repeat: repeat-x;
  background-position: 0 bottom;
  background-color: #eef2f6;
  display: flex;
  align-items: center;
  justify-content: center;
}

.text {
  font-size: 22px;
  margin-left: 10px;
  color: #bfbfbf;
}

</style>