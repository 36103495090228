<template>
  <div class="gloal">
    <div class="table">
      <el-table
        ref="multipleTableRef"
        :data="dataSource.list"
        style="width: 100%; font-size: 13px;"
        @selection-change="handleSelectionChange"
    >
        <el-table-column type="selection">
        </el-table-column>
        <el-table-column property="file_name" label="文件名" width="0" min-width="11" style="font-size: 12px;" >
          <template #default="scope">
            <div 
              @mouseenter="scope.row.isShow = true;"
              @mouseleave="scope.row.isShow = false;"
              style="display: flex; align-items: center;"
            >
              <div class="text" style="display: flex; align-items: center;">
                <div @click="showFileDetail(scope.row)">
                  <FileIcon :file_type="scope.row.file_type" ></FileIcon>
                </div>
                <div @click="showFileDetail(scope.row)" style="margin-right: 7px;"></div>
                <div @click="showFileDetail(scope.row)" class="text-too-long" >
                  {{ scope.row.file_name }}
                </div>
              </div>
              <div style="flex: 1;"></div>
              <div v-show="scope.row.isShow" style="display: flex;">
                <span
                  class="iconfont icon-download hover-style"
                  @click="download(scope.row)"
                  v-if="scope.row.folder_type != 0"
                  >下载</span>
                <span class="iconfont icon-import hover-style" @click="tableSave(scope.row)"
                v-if="scope.row.folder_type != 0 && 
                  !(store.state.is_login == true && store.state.userInfo.user_id == shareInfo.user_id)">
                  保存到我的网盘
                </span>
              </div>
            </div>
          </template>
        </el-table-column>
        <el-table-column property="last_update_time" label="最近修改" width="0" min-width="4">
          <template #default="scope">
            {{ format(scope.row.last_update_time) }}
          </template>
        </el-table-column>
        <el-table-column property="file_size" label="大小" show-overflow-tooltip  width="0" min-width="3">
          <template #default="scope">
            {{ sizeFormat(scope.row.file_size) }}
          </template>
        </el-table-column>
        
    
      </el-table>
    </div>
    <!-- 分页 -->
    <div class="pagination">
        <el-pagination
            v-if="dataSource.totalCount"
            :current-page="dataSource.nowPage"
            :page-size="dataSource.pageSize"
            :page-sizes="[15, 30, 50, 100]"
            small
            background
            layout="total, sizes, prev, pager, next, jumper"
            :total="dataSource.totalCount"
            @size-change="handlePageSizeChange"
            @current-change="handlePageNoChange"
            style="position: absolute; right: 10px; font-size: 13px;"
        />
    </div>
  </div>
</template>

<script>
import FileIcon from './FileIcon';
import { size2Str } from '../Utils/SizeUtil'
import { formatDate } from '../Utils/FormatDate';
import { useStore } from "vuex";

export default {
  name:'TableWebShare',
  components: {
    FileIcon,
  },
  props:['dataSource','dir','shareInfo'],
  setup(props,context) {

    // 空间格式化
    const sizeFormat = size2Str;
    // 时间格式化
    const format = formatDate;

    // vue-x
    const store = useStore();

    // 文件预览
    const showFileDetail = (item) => {
      // 文件夹
      if(item.folder_type == 0) {
        context.emit('changeDir', props.dir + item.file_name + "/", item.file_id)
      }
    }

    // 分页处理
    const handlePageSizeChange = (size) => {
      context.emit('changeSize',size);
    }
    const handlePageNoChange = (page) => {
      context.emit('changeNo',page);
    }

    // 选中处理
    const handleSelectionChange = (selection) => {
      context.emit('updateSelection',selection);
    }

    // 下载
    const download = (item) => {
      context.emit('toDownload',item);
    }

    const tableSave = (item) => {
      context.emit('tableSave',item);
    }

    return {
      sizeFormat,
      showFileDetail,
      format,
      handlePageSizeChange,
      handlePageNoChange,
      handleSelectionChange,
      tableSave,
      download,
      store,

    }
  },

}
</script>

<style scoped>
.gloal {
  position: relative;
  height: 65vh;
}

.pagination {
  position: absolute;
  width: 100%;
  bottom: 10px;
  padding-top: 10px;
  border-top: 1px solid #e0e0e0;
}
.text {
  font-size: 13px;
  cursor: pointer;
}

.text:hover {
  color: #06a7ff;
}

.hover-style {
  margin-right: 8px;
  font-size: 13px;
  color: #06a7ff;
  cursor: pointer;
}

.table {
  height: calc(64vh - 18px);
  overflow-y: scroll;
  overflow-x: hidden;
}

.text-too-long {
  max-width: 22vw; 
  white-space: nowrap; 
  overflow: hidden; 
  text-overflow:ellipsis;
}

</style>