<template>
  <el-dialog
      v-model="DialogVisilble"
      :before-close="closeDialog"
      title="文件分享"
      width="50%"
    >
      <div style="margin-top: -15px;"></div>
      <div class="item-class">
        <div  class="form-class">文件名：&nbsp;&nbsp; {{ shareItem.file_name }} </div>
        <template v-if="showType == 0">
          <el-form-item label="有效期：" prop="validType" class="form-class">
            <el-radio-group v-model="ShareParam.validType">
              <el-radio :label="1" size="small">1天</el-radio>
              <el-radio :label="7" size="small">7天</el-radio>
              <el-radio :label="30" size="small">30天</el-radio>
              <el-radio :label="-1" size="small">永久有效</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="提取码：" prop="codeType" class="form-class">
            <el-radio-group v-model="ShareParam.codeType" >
              <el-radio :label="0" size="small">自定义</el-radio>
              <el-radio :label="1" size="small">系统生成</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item prop="code" v-if="ShareParam.codeType == 0" style="margin-left: 70px;">
            <el-input
              size="small"
              clearable
              placeholder="请输入4位提取码"
              v-model.trim="ShareParam.code"
              maxLength="4"
              :style="{ width: '10vw' }"
            ></el-input>
          </el-form-item>
        </template>
        <div v-if="showType == 1" class="form-class">
          <div style="display: flex; align-items: center;">
            <div style="margin-right: 10px;">分享链接：{{ shareUrl + shareId }}</div>
            <div style="cursor: pointer;" @click="doCopy(shareUrl + shareId)"><el-icon size="15"><DocumentCopy /></el-icon></div>
          </div>
        </div>
        <div v-if="showType == 1" class="form-class">
          <div style="display: flex; align-items: center;">
            <div style="margin-right: 10px;">提取码：&nbsp;&nbsp; {{ shareCode }} </div>
            <div style="cursor: pointer;" @click="doCopy(shareCode)"><el-icon size="15"><DocumentCopy /></el-icon></div>
          </div>
        </div>
        <div v-if="showType == 1" class="form-class">
          <el-button v-if="showType == 1" type="primary" 
            @click="doCopy(shareUrl + shareId + '?code=' + shareCode)" >
          复制链接及提取码
          </el-button>
        </div>
      </div>
      <div class="errInfo"> {{ err_msg }} </div>
      <div style="margin-bottom: 15px;"></div>
      <div style="display: flex; border-top: 1px solid #f1f2f4;">
        <div style="flex: 1;"></div>
        <el-button v-if="showType == 0" type="primary" @click="createShare" style="margin-bottom: -15px; margin-top: 10px;">
          确定
        </el-button>
        <el-button v-if="showType == 1" type="primary" @click="closeDialogByBtn" style="margin-bottom: -15px; margin-top: 10px;">
          关闭
        </el-button>
      </div>
  </el-dialog>
</template>
  
<script>
import useClipboard from "vue-clipboard3";
import { useRouter } from "vue-router";
import { ref,reactive } from 'vue';
import { ElMessage } from 'element-plus'
import $ from "jquery";
import Re from "../Utils/VerifyRe";
import { gotoLogin } from '../Utils/gotoLogin';
import base_url from '../Utils/BaseUrl'
import base_share_url from "@/Utils/BaseShareUrl";

export default {
  name:'ShareDialog',
  components: {
  },
  setup() {

    // vue-router
    const router = useRouter();

    // 拷贝
    const { toClipboard } = useClipboard();

    // 后端api
    const api = {
      share: base_url + 'share/createLink/',
    }

    // 控制Dialog显示
    const DialogVisilble = ref(false);
    const err_msg = ref('');
    // 显示类型
    const showType = ref(0);
    const ShareParam = ref({});

    const shareItem = reactive({file_name:'',file_id:''});
    const shareId = ref('');
    const shareCode = ref('');
    const shareUrl = base_share_url + 'shareCheck/';

    // 创建分享链接
    const createShare = () => {
      // 校验参数
      err_msg.value = '';
      if(ShareParam.value.validType != 1 && ShareParam.value.validType != 7 && 
        ShareParam.value.validType != 30 && ShareParam.value.validType != -1) {
          err_msg.value = '请选择提取码有效期';
          return;
      }
      if(ShareParam.value.codeType == null) {
          err_msg.value = '请选择提取码生成方式';
          return;
      }
      if(ShareParam.value.codeType == 0) {
          if(ShareParam.value.code == null || ShareParam.value.code.length != 4) {
              err_msg.value = '提取码长度必须为四位';
              return;
          }
          if(!Re.shareCode.test(ShareParam.value.code)) {
              err_msg.value = '提取码只能由是数字或字母组成';
              return;
          }
      }

      $.ajax({
        url: api.share,
        type:'post',
        data:{
          file_id:shareItem.file_id,
          time_type:ShareParam.value.validType,
          code_type:ShareParam.value.codeType,
          code:ShareParam.value.code,
        },
        headers: {
          pantoken: localStorage.getItem('pantoken'),
        },
        success(resp){
          ElMessage({
            showClose: true,
            message: resp.info,
            type: resp.status,
          })
          if(resp.status == 'success') {
            err_msg.value = '';
            shareId.value = resp.data.shareId;
            shareCode.value = resp.data.shareCode;
            showType.value = 1;
          }
          gotoLogin(router,resp);
        }
    });
    }

    // 复制
    const doCopy = async (content) => {
      await toClipboard(
        content
      );
      ElMessage({
        showClose: true,
        message: '复制成功',
        type: 'success',
      })
    }

    // 关闭
    const closeDialog = (done) => {
      err_msg.value = '';
      showType.value = 0;
      ShareParam.value = {};
      shareItem.file_id = '';
      shareItem.file_name = '';
      shareId.value = '';
      shareCode.value = '';
      done();
    }

    // 手动关闭
    const closeDialogByBtn = () => {
      err_msg.value = '';
      showType.value = 0;
      ShareParam.value = {};
      shareItem.file_id = '';
      shareItem.file_name = '';
      shareId.value = '';
      shareCode.value = '';
      DialogVisilble.value = false;
    }

    return {
      DialogVisilble,
      err_msg,
      closeDialog,
      showType,
      ShareParam,
      router,
      api,
      createShare,
      closeDialogByBtn,
      shareItem,
      shareId,
      shareCode,
      shareUrl,
      doCopy,

    
    }
  }

}
</script>

<style scoped>
body {
  font-size: 13px;
}
  
.item-class {
  font-size: 13px;
  color: #606266;
}

.errInfo{
  font-size: 12px;
  color: rgb(234, 80, 80);
  margin-top: 5px;
}

.form-class {
  padding: 0 10px;
  margin: 15px 0;
  white-space: nowrap; 
  overflow: hidden;
  text-overflow:ellipsis;
}
  
  
</style>