<template>
  <div>
    <div class="header">
      <img src="../assets/logo.png" class="logoimg" alt="kk网盘">
      <div class="logofont" > kk网盘 </div>
      <div style="flex: 1;"></div>
      <!-- upLoader -->
      <div style="margin-right: 40px ;">
          <el-popover
            placement="bottom"
            v-model:visible="showUploader"
            :width="600"
            trigger="click">
              <template #reference>
                <div style="cursor: pointer; margin-top: 7px;"><span class="iconfont icon-transfer"></span></div>
              </template>
              <template #default>
                <UploadBlock ref="UploadBlockRef" @doGetFileList="doGetFileList">
                </UploadBlock>
              </template>
          </el-popover>
      </div>

      <el-dropdown>
        <span class="el-dropdown-link">
          <div class="Info">
            <!-- 头像 -->
            <div><el-avatar :src="store.state.userInfo.user_image" /></div>
            <!-- vip 管理员标识 -->
            <div v-if="store.state.userInfo.user_state == 2" style="margin-top: 5px; margin-left: 5px; margin-right: -5px;">
              <img src="../assets/vip.png" alt="VIP" style="width: 20px;">
            </div>
            <div v-if="store.state.userInfo.user_state == -1" style="margin-top: 5px; margin-left: 5px; margin-right: -5px;">
              <img src="../assets/管理员.png" alt="管理员" style="width: 35px;">
            </div>
            <!-- 昵称 -->
            <div class="userNameClass a-link"> {{ store.state.userInfo.user_name }} </div>
            <div style="display: inline-block; margin-top: 5px;" ><el-icon class="el-icon--right"><arrow-down /></el-icon></div>
          </div>
        </span>
        <template #dropdown>
          <el-dropdown-menu>
            <el-dropdown-item style="font-size: 12px;" @click="infoBlockRef.DialogVisilble = true" >个人信息</el-dropdown-item>
            <el-dropdown-item style="font-size: 12px;" @click="activeCodeRef.DialogVisilble = true" >激活码</el-dropdown-item>
            <el-dropdown-item style="font-size: 12px;" @click="logoutTipsRef.DialogVisilble = true" >退出</el-dropdown-item>
          </el-dropdown-menu>
        </template>
      </el-dropdown>
    </div>
    <div class="main-body">
      <div class="sider">
        <div class="menu-list-first">
          <div v-for="item in menus" :key="item.id">
            <div
              v-if="item.allShow || (!item.allShow && store.state.userInfo.user_state == -1)"
              @click="doChange(item)"
              :class="['menu-list-first-text',item.menuCode == currentMenu.menuCode ? 'active' : '']">
              <div :class="['iconfont', 'icon-' + item.icon, 'iconfont-first']"></div>
              <div class="text">
                {{ item.name }}
              </div>
            </div>
          </div>
        </div>
        <div class="menu-list-sub">
          <div
            v-for="sub in currentMenu.children" :key="sub.id"
            @click="doChange(sub)"
            :class="['menu-list-sub-text', currentPath == sub.path ? 'sub-active' : '']">
            <span
              v-if="sub.icon"
              :class="['iconfont', 'icon-' + sub.icon, 'iconfont-sub']"></span>
            <span class="text-sub">{{ sub.name }}</span>
          </div>
          <div class="tips" v-if="currentMenu && currentMenu.tips">
            {{ currentMenu.tips }}
          </div>
          <div class="space-info">
            <div style="display: flex; margin-bottom: 7px;" >
              <div style="font-size: 14px;" >空间使用</div>
              <div style="flex: 1;" ></div>
              <div class="iconfont icon-refresh icon-refresh-class" @click="doFreshUseSpace"></div>
            </div>
            <div class="percent">
              <div>
                <el-progress
                  :percentage="store.state.userInfo.total_space > 0 ? Math.floor(
                    (store.state.userInfo.use_space / store.state.userInfo.total_space) * 10000
                  ) / 100 : 100"
                  :color="colorHandler(Math.floor(
                    (store.state.userInfo.use_space / store.state.userInfo.total_space) * 10000
                  ) / 100)"
                  style="font-size: 12px;" />
              </div>
            </div>
            <div class="use">
              {{ sizeFormat(store.state.userInfo.use_space) }} /
              {{ sizeFormat(store.state.userInfo.total_space) }}
            </div>        
          </div>
        </div>
        <div class="body-content">
          <router-view v-slot="{ Component }">
            <component
              @uploadFile="uploadFile"
              :is="Component"
              ref="RouterViewRef"
            />
          </router-view>
        </div>
      </div>
    </div>

    <InfoBlock ref="infoBlockRef" ></InfoBlock>
    <ActiveCode ref="activeCodeRef" ></ActiveCode>
    <TipsBlock ref="logoutTipsRef" :msg="logoutTipsMsg" @confirmReCall="doLogout" ></TipsBlock>

  </div>
</template>

<script>
import { useStore } from "vuex";
import { useRouter,useRoute } from "vue-router";
import { ref } from 'vue';
import { size2Str } from '../Utils/SizeUtil'
import { ElMessage } from 'element-plus'
import UploadBlock from "../components/UploadBlock";
import TipsBlock from '../components/TipsBlock'
import InfoBlock from '../components/InfoBlock'
import ActiveCode from '../components/ActiveCode'

export default {
  name:'FrameWork',
  components: {
    UploadBlock,
    TipsBlock,
    InfoBlock,
    ActiveCode,
  },
  setup() {
    // 空间格式化
    const sizeFormat = size2Str;

    // 进度条获取颜色
    const colorHandler = (percentage) => {
      if (percentage < 33) {
        return '#409eff'
      }
      if (percentage < 66) {
        return '#e6a23c'
      }
      return '#f56c6c'
    }

    // vuex
    const store = useStore();
    // vue-router
    const router = useRouter();
    const route = useRoute();

    // 组件实例
    const RouterViewRef = ref();
    const infoBlockRef = ref();
    const activeCodeRef = ref();

    // 菜单数组
    const menus = [
      {
        id:1,
        icon: "cloude",
        name: "首页",
        menuCode: "home",
        path: "/home/all",
        allShow: true,
        children: [
          {
            id:11,
            icon: "all",
            name: "全部",
            category: "all",
            path: "/home/all",
          },
          {
            id:12,
            icon: "video",
            name: "视频",
            category: "video",
            path: "/home/video",
          },
          {
            id:13,
            icon: "music",
            name: "音频",
            category: "music",
            path: "/home/music",
          },
          {
            id:14,
            icon: "image",
            name: "图片",
            category: "image",
            path: "/home/image",
          },
          {
            id:15,
            icon: "doc",
            name: "文档",
            category: "doc",
            path: "/home/doc",
          },
          {
            id:16,
            icon: "more",
            name: "其他",
            category: "others",
            path: "/home/others",
          },
        ],
      },
      {
        id:2,
        path: "/myshare",
        icon: "share",
        name: "分享",
        menuCode: "share",
        allShow: true,
        children: [
          {
            id:21,
            name: "分享记录",
            path: "/myshare",
          },
        ],
      },
      {
        id:3,
        path: "/recycle",
        icon: "del",
        name: "回收站",
        menuCode: "recycle",
        tips: "彻底删除文件才可释放空间",
        allShow: true,
        children: [
          {
            id:31,
            name: "删除的文件",
            path: "/recycle",
          },
        ],
      },
      {
        id:4,
        path: "/settings/file",
        icon: "settings",
        name: "管理员",
        menuCode: "settings",
        allShow: false,
        children: [
          {
            id:41,
            name: "文件",
            path: "/settings/file",
            icon: "fileFloder",
            category: "file",
          },
          {
            id:42,
            name: "用户",
            path: "/settings/user",
            icon: "user",
            category: "user",
          },
          {
            id:43,
            path: "/settings/activationCode",
            name: "激活",
            icon: "activeCode",
            category: "activationCode",
          },
        ],
      },
    ];
    

    // 切换菜单
    const currentMenu = ref({...menus[0]});
    const currentPath = ref("/home/all");
    for(let i=0;i<menus.length;i++) {
      for(let j=0;j<menus[i].children.length;j++) {
        if(route.fullPath == menus[i].children[j].path) {
          currentMenu.value = menus[i];
          currentPath.value = route.fullPath;
        }
      }
    }

    const doChange = (item) => {
      if(item.menuCode) {
        if(!item.path || currentMenu.value.menuCode == item.menuCode) return;
        currentMenu.value = {...item};
      }
      currentPath.value = item.path;
      
      router.push(item.path);
    }
    
    // 展示上传列表
    const showUploader = ref(false);

    // 文件上传
    const UploadBlockRef = ref();
    const uploadFile = async (file,dir) => {
      showUploader.value = true;
      await UploadBlockRef.value.uploadFile(file,dir);
    }

    // 退出登录
    const logoutTipsRef = ref();
    const logoutTipsMsg = ref('确定要退出吗？');
    const doLogout = () => {
      localStorage.removeItem('pantoken');
      ElMessage({
        showClose: true,
        message: "退出成功",
        type: "success",
      })
      router.push({
        name: 'LoginView'
      })
    }

    // 更新展示文件列表
    const doGetFileList = () => {
      RouterViewRef.value.doGetFileList();
    }

    // 更新网盘空间
    const doFreshUseSpace = () => {
      store.dispatch('doUpdateSpaceByToken',function(resp) {
        if(resp.status != 'success') {
          ElMessage({
            showClose: true,
            message: resp.info,
            type: resp.status,
          })
          router.push({name:'LoginView'});
        }
      })
    }

      
    return {
      store,
      menus,
      currentMenu,
      doChange,
      currentPath,
      sizeFormat,
      colorHandler,
      doLogout,
      showUploader,
      UploadBlockRef,
      uploadFile,
      RouterViewRef,
      doGetFileList,
      doFreshUseSpace,
      logoutTipsRef,
      logoutTipsMsg,
      infoBlockRef,
      activeCodeRef,


    }
  }

}
</script>

<style scoped>
.img:hover {
  cursor: pointer;
}
.header{
  box-shadow: 0 3px 10px 0 rgb(0 0 0 / 6%);
  height: 50px;
  padding-left: 24px;
  padding-right: 24px;
  position: relative;
  z-index: 200;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.logoimg{
  width: 50px;
}

.logofont{
  font-size: 20px;
  font-weight: bold;
  color: #06a7ff;
  margin-left: 7px;
}

.userNameClass{
  display: inline-block;
  margin-top: 7px;
  margin-left: 10px;
  margin-right: 5px;
}

.Info {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.main-body{
  display: flex;
}

.sider{
  border-right: 1px solid #f1f2f4;
  display: flex;
}

.menu-list-first{
  height: calc(100vh - 50px);
  width: 7vw;
  box-shadow: 0 3px 10px 0 rgb(0 0 0 / 6%);
  border-right: 1px solid #f1f2f4;
}

.menu-list-first-text{
  text-align: center;
  padding: 15px 0;
  cursor: pointer;
}

.menu-list-first-text:hover{
  background: #f3f3f3;
}

.active{
  color: #06a7ff;
}
.iconfont-first {
  font-size: 25px;
}

.text{
  font-weight: bold;
  font-size: 13px;
}

.menu-list-sub {
  height: calc(100vh - 50px);
  width: 15vw;
  border-right: 1px solid #f1f2f4;
  padding-top: 10px;
  position: relative;
}

.menu-list-sub-text{
  text-align: center;
  padding: 10px 0;
  cursor: pointer;
}

.menu-list-sub-text:hover{
  background: #f3f3f3;
}

.iconfont-sub {
  font-size: 15px;
  margin-right: 15px;
}

.text-sub {
  font-size: 13px;
}

.sub-active {
  background-color: #eef9fe;
  color: #06a7ff;
}

.space-info {
  position: absolute;
  bottom: 10px;
  width: 100%;
  padding: 0px 10px;
  font-size: 12px;
}

.use {
  font-size: 12px;
}

.icon-refresh-class{
  font-size: 15px;
  color: #06a7ff;
  cursor: pointer;
}

.tips{
  font-size: 12px;
  padding: 10px 10px;
  text-align: center;
}


</style>