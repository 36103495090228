export default {
    "all": {
        accept: "*"
    },
    "video": {
        accept: ".mp4,.avi,.rmvb,.mkv,.mov"
    },
    "music": {
        accept: ".mp3,.wav,.wma,.mp2,.flac,.midi,.ra,.ape,.aac,.cda"
    },
    "image": {
        accept: ".jpeg,.jpg,.png,.gif,.bmp,.dds,.psd,.pdt,.webp,.xmp,.svg,.tiff"
    },
    "doc": {
        accept: ".pdf,.doc,.docx,.xls,.xlsx,.txt"
    },
    "others": {
        accept: "*"
    },
}