<template>
  <el-dialog
    v-model="DialogVisilble"
    :before-close="closeDialog"
    title="信息面板"
    width="40%"
    draggable
    align-center
  >
    <div style="margin-top: -15px;"></div>
    <div class="item-class">
        <div class="info-item" style="margin-top: 0;">
            id：{{ store.state.userInfo.user_id }}
        </div>
        <div class="info-item" style="width: 100%;">
            <div>昵称：</div>
            <div style="display: inline-block;" >{{ store.state.userInfo.user_name }}</div>
        </div>
        <div class="info-item">
            <div v-if="store.state.userInfo.user_state == 1">
                <div class="common-class"><span style="color: #606266;">用户组：</span><span>普通用户</span></div>
                <div class="common-text">*普通用户下载限速100kB/s，升级VIP享受极速下载</div>
            </div>
            <div v-if="store.state.userInfo.user_state == 2">
                <div class="vip-class"><span style="color: #606266;">用户组：</span><span>VIP用户</span></div>
                <div class="vip-text">*极速下载权限生效中</div>
            </div>
            <div v-if="store.state.userInfo.user_state == -1">
                <div class="admin-class"><span style="color: #606266;">用户组：</span><span>管理员</span></div>
                <div class="admin-text">*极速下载权限生效中</div>
            </div>
            <div style="flex: 1;"></div>
            <div>
                <el-button style="width: 85px;" :disabled="!(store.state.userInfo.user_state == 1)" size="small" :icon="Operation" type="primary" @click="ActiveCodeRef.DialogVisilble = true" >
                  升级VIP
                </el-button>
            </div>
        </div>
        <div class="info-item">
            <div>头像：</div>
            <div style="display: inline-block; margin-left: 10px;">
                <img :src="store.state.userInfo.user_image" style="width: 80px;" />
            </div>
            <div style="flex: 1;"></div>
            <div>
                <el-button style="width: 85px;" size="small" :icon="PictureFilled" type="primary" @click="ImageDialogRef.DialogVisilble = true" >
                  上传头像
                </el-button>
            </div>
        </div>
        <div class="info-item">
            <div>密码：**********</div>
            <div style="flex: 1;"></div>
            <div>
                <el-button style="width: 85px;" size="small" :icon="Edit" type="primary" @click="PasswordDialogRef.DialogVisilble = true" >
                  修改密码
                </el-button>
            </div>
        </div>
        <div class="info-item">
            <div>
                <span>QQ：</span>
                <span v-if="store.state.userInfo.qq_name && store.state.userInfo.qq_name != ''">
                    <img src="../assets/qq.png" alt="QQ" style="width: 14px; margin-right: 2px;">
                    {{ store.state.userInfo.qq_name }}
                </span>
                <span v-else style="color: #b1b3b8;">未绑定</span>
            </div>
            <div style="flex: 1;"></div>
            <div>
                <el-button style="width: 85px;" v-if="store.state.userInfo.qq_name && store.state.userInfo.qq_name != ''"
                 size="small" :disabled="true" :icon="CircleCheck" type="primary">
                  已绑定
                </el-button>
                <el-button style="width: 85px;" v-else
                 size="small" :icon="Paperclip" type="primary" @click="doQQBind" >
                  绑定QQ
                </el-button>
            </div>
        </div>
        <div class="info-item">
            <div>
                <span>Github：</span>
                <span v-if="store.state.userInfo.github_name && store.state.userInfo.github_name != ''">
                    <img src="../assets/github.png" alt="github_name" style="width: 20px; margin-right: 2px;">
                    {{ store.state.userInfo.github_name }}
                </span>
                <span v-else style="color: #b1b3b8;">未绑定</span>
            </div>
            <div style="flex: 1;"></div>
            <div>
                <el-button style="width: 85px;" v-if="store.state.userInfo.github_name && store.state.userInfo.github_name != ''"
                 size="small" :disabled="true" :icon="CircleCheck" type="primary">
                  已绑定
                </el-button>
                <el-button style="width: 85px;" v-else
                 size="small" :icon="Paperclip" type="primary" @click="doGithubBind" >
                  绑定Github
                </el-button>
            </div>
        </div>
        <div class="info-item" style="margin-bottom: 0;">
            <div>上次登录：{{ formatDate(store.state.userInfo.last_login_time) }}</div>
        </div>
    </div>
    <div style="margin-bottom: 15px;"></div>
    <div style="display: flex; border-top: 1px solid #f1f2f4;">
      <div style="flex: 1;"></div>
      <el-button type="primary" @click="doClose" style="margin-bottom: -15px; margin-top: 10px;">
        关闭
      </el-button>
    </div>
  </el-dialog>


  <!-- 修改头像 -->
  <ModifyImage ref="ImageDialogRef"></ModifyImage>
  <!-- 修改密码 -->
  <ModifyPassword ref="PasswordDialogRef"></ModifyPassword>
  <!-- 激活码 -->
  <ActiveCode ref="ActiveCodeRef" ></ActiveCode>

</template>

<script>
import { ref } from 'vue';
import { useStore } from "vuex";
import { useRoute } from "vue-router";
import ModifyImage from "./ModifyImage";
import ModifyPassword from "./ModifyPassword";
import { formatDate } from '../Utils/FormatDate'
import ActiveCode from './ActiveCode'
import $ from "jquery";
import { ElMessage } from 'element-plus'
import base_url from '@/Utils/BaseUrl';

import {
  PictureFilled,
  Edit,
  Paperclip,
  CircleCheck,
  Operation,
} from '@element-plus/icons-vue'

export default {
  name:'InfoBlock',
  components: {
    ModifyImage,
    ModifyPassword,
    ActiveCode,
  },
  setup() {

    // vuex
    const store = useStore();

    // vue-route
    const route = useRoute();

    const api = {
      qq_bind: base_url + 'qq/doBind/',
      github_bind: base_url + 'github/doBind/',
    }

    // 控制Dialog显示
    const DialogVisilble = ref(false);

    const ImageDialogRef = ref();
    const PasswordDialogRef = ref();
    const ActiveCodeRef = ref();

    // 绑定QQ
    const doQQBind = () => {
      $.ajax({
        url: api.qq_bind,
        type:'get',
        data:{
          callbackUrl: '/',
        },
        headers: {
          pantoken: localStorage.getItem('pantoken'),
        },
        success(resp){
          if(resp.status != 'success') {
            ElMessage({
              showClose: true,
              message: resp.info,
              type: resp.status,
            })
          }
          if(resp.status == 'success') {
            window.location.href = resp.data.url;
          }
        }
      });
    }

    // 绑定Github
    const doGithubBind = () => {
      $.ajax({
        url: api.github_bind,
        type:'get',
        data:{
          callbackUrl: '/',
        },
        headers: {
          pantoken: localStorage.getItem('pantoken'),
        },
        success(resp){
          if(resp.status != 'success') {
            ElMessage({
              showClose: true,
              message: resp.info,
              type: resp.status,
            })
          }
          if(resp.status == 'success') {
            window.location.href = resp.data.url;
          }
        }
      });
    }

    // 关闭
    const closeDialog = (done) => {
      PasswordDialogRef.value.DialogVisilble = false;
      ImageDialogRef.value.DialogVisilble = false;
      ActiveCodeRef.value.DialogVisilble = false;
      done();
    }

    // 手动关闭
    const doClose = () => {
      PasswordDialogRef.value.DialogVisilble = false;
      ImageDialogRef.value.DialogVisilble = false;
      ActiveCodeRef.value.DialogVisilble = false;
      DialogVisilble.value = false;
    }

    return {
      DialogVisilble,
      closeDialog,
      ImageDialogRef,
      PasswordDialogRef,
      ActiveCodeRef,
      store,
      PictureFilled,
      Edit,
      doClose,
      Paperclip,
      CircleCheck,
      Operation,
      formatDate,
      doQQBind,
      route,
      doGithubBind,


    }
  }

}
</script>

<style scoped>
.info-item {
  width: 90%;
  display: flex; 
  align-items: center; 
  vertical-align: center;
  margin: 20px 0;
  color: #606266;
}

.commom-class {
  color: #909399;
}

.vip-class {
  color: #e6a23c;
}

.admin-class {
  color: #f56c6c;
}

.common-text {
  margin-top: 3px;
  font-size: 12px;
  color: #b1b3b8;
}

.vip-text {
  margin-top: 3px;
  font-size: 12px;
  color: #eebe77;
}

.admin-text {
  margin-top: 3px;
  color: #f89898;
  font-size: 12px;
}

</style>