<template>
  <div class="gloal">
      <div class="table">
        <el-table
          ref="multipleTableRef"
          :data="dataSource.list"
          style="width: 100%; font-size: 13px;"
          @selection-change="handleSelectionChange"
      >
          <el-table-column type="selection">
          </el-table-column>
          <el-table-column property="file_name" label="文件名" width="0" min-width="12" style="font-size: 12px;" >
            <template #default="scope">
              <div 
                @mouseenter="scope.row.isShow = true;"
                @mouseleave="scope.row.isShow = false;"
                style="display: flex; align-items: center;"
              >
                <div class="text" style="display: flex; align-items: center;">
                  <div>
                    <FileIcon :file_type="scope.row.file_type" ></FileIcon>
                  </div>
                  <div style="margin-right: 7px;"></div>
                  <div class="text-too-long">
                    {{ scope.row.file_name }}
                  </div>
                </div>
                <div style="flex: 1;"></div>
                <div v-show="scope.row.isShow" style="display: flex;">
                  <span class="iconfont icon-revert hover-style" @click="doOneRevert(scope.row)">还原</span>
                  <span class="iconfont icon-del hover-style" @click="doOneDeepDel(scope.row)">彻底删除</span>
                </div>
              </div>
            </template>
          </el-table-column>
          <el-table-column property="recovery_time" label="删除时间" width="0" min-width="4">
            <template #default="scope">
              {{ format(scope.row.recovery_time) }}
            </template>
          </el-table-column>
          <el-table-column property="file_size" label="大小" show-overflow-tooltip  width="0" min-width="4">
            <template #default="scope">
              {{ sizeFormat(scope.row.file_size) }}
            </template>
          </el-table-column>
          
      
        </el-table>
      </div>
      <!-- 分页 -->
      <div class="pagination">
          <el-pagination
              v-if="dataSource.totalCount"
              :current-page="dataSource.nowPage"
              :page-size="dataSource.pageSize"
              :page-sizes="[15, 30, 50, 100]"
              small
              background
              layout="total, sizes, prev, pager, next, jumper"
              :total="dataSource.totalCount"
              @size-change="handlePageSizeChange"
              @current-change="handlePageNoChange"
              style="position: absolute; right: 10px; font-size: 13px;"
          />
      </div>
  </div>
</template>

<script>
import FileIcon from './FileIcon';
import { size2Str } from '../Utils/SizeUtil'
import { formatDate } from '../Utils/FormatDate';

export default {
  name:'TableRecycle',
  components: {
    FileIcon,
  },
  props:['dataSource','dir'],
  setup(props,context) {

    // 空间格式化
    const sizeFormat = size2Str;
    // 时间格式化
    const format = formatDate;

    // 分页处理
    const handlePageSizeChange = (size) => {
      context.emit('changeSize',size);
    }
    const handlePageNoChange = (page) => {
      context.emit('changeNo',page);
    }

    // 选中处理
    const handleSelectionChange = (selection) => {
      context.emit('updateSelection',selection);
    }
    // 还原
    const doOneRevert = (item) => {
        context.emit('doOneRevert',item);
    }
    // 删除
    const doOneDeepDel = (item) => {
        context.emit('doOneDeepDel',item);
    }

    


    return {
      sizeFormat,
      format,
      handlePageSizeChange,
      handlePageNoChange,
      handleSelectionChange,
      doOneRevert,
      doOneDeepDel,

    }
  },

}
</script>

<style scoped>
.gloal {
    position: relative;
    height: 70vh;
}

.pagination {
    position: absolute;
    width: 100%;
    bottom: 10px;
    padding-top: 10px;
    border-top: 1px solid #e0e0e0;
}
.text {
  font-size: 13px;
  cursor: pointer;
}

.text:hover {
  color: #06a7ff;
}

.hover-style {
  margin-right: 8px;
  font-size: 13px;
  color: #06a7ff;
  cursor: pointer;
}

.table {
  height: 66vh;
  overflow-y: scroll;
  overflow-x: hidden;
}

.text-too-long {
  max-width: 26vw; 
  white-space: nowrap; 
  overflow: hidden;
  text-overflow:ellipsis;
}

</style>