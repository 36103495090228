<template>
  <div class="gloal">
      <div class="table">
        <el-table
          ref="multipleTableRef"
          :data="dataSource.list"
          style="width: 100%; font-size: 13px;"
          @selection-change="handleSelectionChange"
      >
          <el-table-column type="selection">
          </el-table-column>
          <el-table-column property="file_name" label="文件名" width="0" min-width="10" style="font-size: 12px;" >
            <template #default="scope">
              <div 
                @mouseenter="scope.row.isShow = true;"
                @mouseleave="scope.row.isShow = false;"
                style="display: flex; align-items: center;"
              >
                <div class="text" style="display: flex; align-items: center;">
                  <div>
                    <FileIcon :file_type="scope.row.file_type" ></FileIcon>
                  </div>
                  <div style="margin-right: 7px;"></div>
                  <div class="text-too-long">
                    {{ scope.row.file_name }}
                  </div>
                </div>
                <div style="flex: 1;"></div>
                <div v-show="scope.row.isShow" style="display: flex;">
                  <span class="iconfont icon-link hover-style" @click="doCopyLink(scope.row)">复制链接</span>
                  <span class="iconfont icon-cancel hover-style" @click="doOneCancelShare(scope.row)">取消分享</span>
                </div>
              </div>
            </template>
          </el-table-column>
          <el-table-column property="create_time" label="分享时间" width="0" min-width="4">
            <template #default="scope">
              {{ format(scope.row.create_time) }}
            </template>
          </el-table-column>
          <el-table-column property="valid_time" label="失效时间" width="0" min-width="4">
            <template #default="scope">
              {{ scope.row.valid_time ? format(scope.row.valid_time) : '永久' }}
            </template>
          </el-table-column>
          <el-table-column property="code" label="提取码" show-overflow-tooltip  width="0" min-width="3">
            <template #default="scope">
              <div style="display: flex; align-items: center;">
                <div style="margin-right: 5px;">{{ scope.row.code }}</div>
                <div style="cursor: pointer;" @click="copyCode(scope.row.code)"><el-icon size="15"><DocumentCopy /></el-icon></div>
              </div>
            </template>
          </el-table-column>
          
      
        </el-table>
      </div>
      <!-- 分页 -->
      <div class="pagination">
          <el-pagination
            v-if="dataSource.totalCount"
            :current-page="dataSource.nowPage"
            :page-size="dataSource.pageSize"
            :page-sizes="[15, 30, 50, 100]"
            small
            background
            layout="total, sizes, prev, pager, next, jumper"
            :total="dataSource.totalCount"
            @size-change="handlePageSizeChange"
            @current-change="handlePageNoChange"
            style="position: absolute; right: 10px; font-size: 13px;"
          />
      </div>
  </div>
</template>

<script>
import useClipboard from "vue-clipboard3";
import { ElMessage } from 'element-plus'
import FileIcon from './FileIcon';
import { formatDate } from '../Utils/FormatDate';
import base_share_url from "@/Utils/BaseShareUrl";

export default {
  name:'TableShare',
  components: {
    FileIcon,
  },
  props:['dataSource','dir'],
  setup(props,context) {
    // 时间格式化
    const format = formatDate;

    // 拷贝
    const { toClipboard } = useClipboard();

    // 分页处理
    const handlePageSizeChange = (size) => {
      context.emit('changeSize',size);
    }
    const handlePageNoChange = (page) => {
      context.emit('changeNo',page);
    }

    // 选中处理
    const handleSelectionChange = (selection) => {
      context.emit('updateSelection',selection);
    }
    // 复制链接
    const doCopyLink = async (item) => {
      await toClipboard(
        base_share_url + 'shareCheck/' + item.share_id
      );
      ElMessage({
        showClose: true,
        message: '复制成功',
        type: 'success',
      })
    }
    // 复制提取码
    const copyCode = async (code) => {
      await toClipboard(
        code
      );
      ElMessage({
        showClose: true,
        message: '复制成功',
        type: 'success',
      })
    }
    // 取消分享
    const doOneCancelShare = (item) => {
      context.emit('doOneCancelShare',item);
    }

    


    return {
      format,
      handlePageSizeChange,
      handlePageNoChange,
      handleSelectionChange,
      doCopyLink,
      doOneCancelShare,
      copyCode,

    }
  },

}
</script>

<style scoped>
.gloal {
  position: relative;
  height: 70vh;
}

.pagination {
  position: absolute;
  width: 100%;
  bottom: 10px;
  padding-top: 10px;
  border-top: 1px solid #e0e0e0;
}
.text {
  font-size: 13px;
  cursor: pointer;
}

.text:hover {
  color: #06a7ff;
}

.hover-style {
  margin-right: 8px;
  font-size: 13px;
  color: #06a7ff;
  cursor: pointer;
}

.table {
  height: 66vh;
  overflow-y: scroll;
  overflow-x: hidden;
}

.text-too-long {
  max-width: 15vw; 
  white-space: nowrap; 
  overflow: hidden;
  text-overflow:ellipsis;
}

</style>