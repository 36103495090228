<template>
  <div class="global">
    <div class="content">
      <div class="bar">
        <div style="font-weight: bold; font-size: 12px;">昵称</div>
        <el-input style="width: 180px; margin-left: 7px;" v-model="searchUserName" placeholder="输入昵称搜索" clearable class="input-style" />
        <div style="font-weight: bold; font-size: 12px; margin-left: 10px; margin-right: 7px;">用户组</div>
        <div style="font-size: 12px;">
          <el-select clearable v-model="now_op" class="m-2" style="width: 140px;" placeholder="请选择用户组">
            <el-option
              v-for="item in options"
              :key="item.value"
              :label="item.label"
              :value="item.value"
              style="font-size: 12px; margin-top: 1px; margin-bottom: 3px;"
              @click="now_op = item.label; now_type = item.value"
            />
          </el-select>
        </div>
        <div>
          <el-button :icon="Search" size="small" type="primary" @click="searchUser" style="margin-left: 15px;">
            查询
          </el-button>
        </div>
      </div>
      <div class="body">
        <DirShow :dir="dir" @changeDir="changeDir"></DirShow>
      </div>
      <div v-if="dataSource.totalCount" >
        <TableAdminUser :dataSource="dataSource"
         @changeSize="changeSize" @changeNo="changeNo" 
         @tableDoSpace="tableDoSpace" @upUserLevel="upLevelShow"
         @disabledUser="disabledUser" >
        </TableAdminUser>
      </div>
      <div v-else>
        <NoData :msg="noDataMsg">
          <template v-slot:forMid>
            <div style="height: 15vh;"></div>
          </template>
        </NoData>
      </div>
    </div>
  </div>

  <AllocateSpace ref="allocateSpaceRef" ></AllocateSpace>
  <TipsBlock ref="upUserLevelRef" @confirmReCall="upUserLevel" :msg="upUserLevelMsg" ></TipsBlock>
  <TipsBlock ref="disabledUserRef" @confirmReCall="doDisabledUser" 
    :msg="disabledType == 0 ? disabledMsg0 : disabledMsg1" ></TipsBlock>

</template>

<script>
import { ref } from 'vue';
import $ from "jquery";
import { ElMessage } from 'element-plus'
import { useRouter } from 'vue-router'
import { gotoLogin } from '../../Utils/gotoLogin';
import TableAdminUser from '../../components/TableAdminUser'
import NoData from '../../components/NoData';
import DirShow from "../../components/DirShow";
import TipsBlock from '../../components/TipsBlock'
import AllocateSpace from '../../components/AllocateSpace'
import base_url from '../../Utils/BaseUrl'

import {
  Search,
} from '@element-plus/icons-vue'


export default {
  name:'AdminUser',
  components: {
    TableAdminUser,
    NoData,
    DirShow,
    TipsBlock,
    AllocateSpace,
  },
  setup() {

    // vue-router
    const router = useRouter();

    const dir = ref('user');
    const noDataMsg = ref('暂无相关数据');

    // 状态数组
    const now_op = ref('');
    const now_type = ref(-2);
    const options = [
      {
        value: -2,
        label: '全部用户',
      },
      {
        value: 0,
        label: '禁用',
      },
      {
        value: 1,
        label: '普通用户',
      },
      {
        value: 2,
        label: 'VIP用户',
      },
      {
        value: -1,
        label: '管理员',
      },
  ]

    // 后端api
    const api = {
      get_all_user:base_url + 'admin/getAllUser/',
      up_level:base_url + 'admin/upLevel/',
      disabled_user:base_url + 'admin/disabledUser/',
    }

    // 搜索文件
    const searchUserName = ref('');
    const searchUser = () => {
      doAdminGetUserList();
    }

    // 表格信息
    const dataSource = ref({
      list:[],
      totalCount:0,
      nowPage:1,
      pageSize:15,
    });

    // 管理员获取所有用户列表
    const adminGetList = (nowPage, pageSize) => {
      $.ajax({
        url: api.get_all_user,
        type:'get',
        data:{
          nowPage:nowPage,
          pageSize:pageSize,
          keyWord:searchUserName.value,
          state:now_type.value,
        },
        headers: {
          pantoken: localStorage.getItem('pantoken'),
        },
        success(resp){
          if(resp.status == 'success') {
            dataSource.value = resp.data;
          } else {
            ElMessage({
              showClose: true,
              message: resp.info,
              type: resp.status,
            })
          }
          gotoLogin(router,resp);
        }
      });
    }
    const doAdminGetUserList = () => {
      dataSource.value.list = [];
      adminGetList(dataSource.value.nowPage,dataSource.value.pageSize);
    }
    doAdminGetUserList();
    const changeSize = (size) => {
      dataSource.value.pageSize = size;
      doAdminGetUserList();
    }
    const changeNo = (page) => {
      dataSource.value.nowPage = page;
      doAdminGetUserList();
    }

    // 用户空间操作
    const allocateSpaceRef = ref();
    const tableDoSpace = (item) => {
      allocateSpaceRef.value.selected_user = item;
      allocateSpaceRef.value.DialogVisilble = true;
    }

    // 升级用户组
    const upUserLevelRef = ref();
    const upUserLevelMsg = ref('确定要提升该用户的用户组吗？')
    const upUserEmail = ref('');
    const upLevelShow = (email) => {
      upUserEmail.value = email;
      upUserLevelRef.value.DialogVisilble = true;
    } 
    const upUserLevel = () => {
      $.ajax({
        url: api.up_level,
        type:'post',
        data:{
          email:upUserEmail.value,
        },
        headers: {
          pantoken: localStorage.getItem('pantoken'),
        },
        success(resp){
          ElMessage({
            showClose: true,
            message: resp.info,
            type: resp.status,
          })
          upUserLevelRef.value.DialogVisilble = false;
          doAdminGetUserList();

          gotoLogin(router,resp);
        }
      });
    }

    // 启用禁用用户
    const disabledUserRef = ref();
    const disabledType = ref(0);
    const disabledMsg0 = ref('确定要禁用该用户吗？');
    const disabledMsg1 = ref('确定要启用该用户吗？');
    const disabledUserEmail = ref('');
    const disabledUser = (email,type) => {
      disabledUserEmail.value = email;
      disabledType.value = type;
      disabledUserRef.value.DialogVisilble = true;
    }
    const doDisabledUser = () => {
      $.ajax({
        url: api.disabled_user,
        type:'post',
        data:{
          email:disabledUserEmail.value,
          type:disabledType.value,
        },
        headers: {
          pantoken: localStorage.getItem('pantoken'),
        },
        success(resp){
          ElMessage({
            showClose: true,
            message: resp.info,
            type: resp.status,
          })
          disabledUserRef.value.DialogVisilble = false;
          doAdminGetUserList();

          gotoLogin(router,resp);
        }
      });
    }

    return {
      dir,
      noDataMsg,
      searchUserName,
      searchUser,
      adminGetList,
      changeSize,
      changeNo,
      dataSource,
      options,
      now_op,
      now_type,
      Search,
      allocateSpaceRef,
      tableDoSpace,
      upUserLevelRef,
      upUserLevelMsg,
      upUserLevel,
      disabledUserRef,
      disabledUser,
      disabledType,
      doDisabledUser,
      disabledMsg0,
      disabledMsg1,
      disabledUserEmail,
      upLevelShow,
      


    }
  }

}
</script>

<style scoped>
.btn-style{
  font-size: 12px;
  height: 30px;
}

.global{
  margin: 0 15px;
  width: calc(75vw);
}

.bar {
  display: flex;
  align-items: center;
  margin: 15px 0;
  margin-bottom: 10px;
}


.input-style {
  width: 25vw;
  margin: 0 8px;
  margin-left: 15px;
  font-size: 13px;
}

.body {
  font-size: 13px;
  font-weight: bold;
  margin-bottom: 5px;
}

</style>