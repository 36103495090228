<template>
  <el-dialog
    v-model="DialogVisilble"
    title="上传头像"
    width="30%"
  >
    <div style="margin-top: -15px;"></div>
    <div class="item-class">
        <div class="text">昵称: 
            <div style="display: inline-block; margin-left: 10px;" >{{ store.state.userInfo.user_name }}</div>
        </div>
        <div style="display: flex; align-items: center; vertical-align: center;">
            <div>头像: </div>
            <div style="display: inline-block; margin-left: 10px; margin-right: 20px;">
                <img :src="imageUrl" style="width: 80px;" />
            </div>
            <div>
              <el-upload
                  name="file"
                  :show-file-list="false"
                  accept=".png,.PNG,.jpg,.JPG,.jpeg,.JPEG,.gif,.GIF,.bmp,.BMP"
                  :multiple="false"
                  :http-request="uploadImage"
              >
                  <el-button :icon="UploadFilled" size="small" type="primary" >选择</el-button>
              </el-upload>
            </div>
        </div>
    </div>
    <div style="margin-bottom: 15px;"></div>
    <div style="display: flex; border-top: 1px solid #f1f2f4;">
      <div style="flex: 1;"></div>
      <el-button type="primary" @click="doModifyImage" style="margin-bottom: -15px; margin-top: 10px;">
        确定
      </el-button>
    </div>
  </el-dialog>
</template>

<script>
import { useStore } from "vuex";
import { ref } from 'vue';
import { ElMessage } from 'element-plus'
import $ from "jquery";
import { gotoLogin } from '../Utils/gotoLogin';
import { useRouter } from "vue-router";
import base_url from '../Utils/BaseUrl'
import {
  UploadFilled,
} from '@element-plus/icons-vue'

export default {
  name:'ModifyImage',
  components: {
  },
  setup() {
    // vuex
    const store = useStore();

    // vue-router
    const router = useRouter();

    // 后端api
    const api = {
        upload: base_url + 'uploadImage/',
        modify: base_url + 'modify/image/',
    }

    // 控制Dialog显示
    const DialogVisilble = ref(false);

    // 头像网址
    const imageUrl = ref(store.state.userInfo.user_image);

    // 上传图片
    const uploadImage = (file) => {
      const fileTarget = file.file;
      // 校验大小 <= 10M
      if(fileTarget.size > 10*1024*1024) {
        ElMessage({
          showClose: true,
          message: "图片文件过大",
          type: "warning",
        })
      }
      const formData = new FormData();
      formData.append('file',fileTarget);
      $.ajax({
        url: api.upload,
        type:'post',
        data: formData,
        contentType : false,
        processData : false,
        headers: {
          pantoken: localStorage.getItem('pantoken'),
        },
        success(resp){
          if(resp.status != 'success') {
            ElMessage({
              showClose: true,
              message: resp.info,
              type: resp.status,
            })
          }
          else {
            imageUrl.value = resp.data.url;
          }
          gotoLogin(router,resp);
        }
      });
    }


    // 修改头像
    const doModifyImage = () => {
      $.ajax({
        url: api.modify,
        type:'post',
        data: {
          url: imageUrl.value
        },
        headers: {
          pantoken: localStorage.getItem('pantoken'),
        },
        success(resp){
          ElMessage({
            showClose: true,
            message: resp.info,
            type: resp.status,
          })
          if(resp.status == 'success') {
            store.commit('updateImage',imageUrl.value);
            DialogVisilble.value = false;
          }
          gotoLogin(router,resp);
        }
      });
    }

    return {
        store,
        doModifyImage,
        DialogVisilble,
        imageUrl,
        uploadImage,
        UploadFilled,
    }
  }

}
</script>

<style scoped>

.item-class {
  font-size: 13px;
  color: #606266;
}

.text {
  margin-bottom: 20px;
}


</style>