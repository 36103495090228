<template>
  <el-dialog
      v-model="DialogVisilble"
      :before-close="closeDialog"
      title="生成激活码"
      width="50%"
      draggable
      align-center
    >
      <div style="margin-top: -15px;"></div>
      <div class="item-class">
        <template v-if="showType == 0">
          <el-form-item label="有效期：" class="form-class">
            <el-radio-group v-model="ActiveParam.validType">
              <el-radio :label="7" size="small">7天</el-radio>
              <el-radio :label="30" size="small">30天</el-radio>
              <el-radio :label="365" size="small">365天</el-radio>
              <el-radio :label="-1" size="small">永久有效</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="数量：" class="form-class">
            <el-radio-group v-model="ActiveParam.count">
              <el-radio :label="1" size="small">1个</el-radio>
              <el-radio :label="5" size="small">5个</el-radio>
              <el-radio :label="10" size="small">10个</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="类型：" class="form-class">
            <el-radio-group v-model="ActiveParam.codeType" >
              <el-radio :label="0" size="small">升级VIP</el-radio>
              <el-radio :label="1" size="small">空间扩容</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="扩容大小：" v-if="ActiveParam.codeType == 1">
            <el-radio-group v-model="ActiveParam.sizeType" >
                <el-radio :label="1" size="small">1GB</el-radio>
                <el-radio :label="3" size="small">3GB</el-radio>
                <el-radio :label="5" size="small">5GB</el-radio>
            </el-radio-group>
          </el-form-item>
        </template>
        <div v-if="showType == 1" class="form-class">
          <div v-for="(activeCode,index) in activeCodeList" :key="index" style="display: flex; align-items: center; margin: 10px 0;">
            <div style="margin-right: 10px;">激活码：{{ activeCode }}</div>
            <div style="cursor: pointer;" @click="doCopy(activeCode)"><el-icon size="15"><DocumentCopy /></el-icon></div>
          </div>
        </div>
      </div>
      <div class="errInfo"> {{ err_msg }} </div>
      <div style="margin-bottom: 15px;"></div>
      <div style="display: flex; border-top: 1px solid #f1f2f4;">
        <div style="flex: 1;"></div>
        <el-button v-if="showType == 0" type="primary" @click="createCode" style="margin-bottom: -15px; margin-top: 10px;">
          确定
        </el-button>
        <el-button v-if="showType == 1" type="primary" @click="copyAll" style="margin-bottom: -15px; margin-top: 10px; margin-right: 15px;">
          复制全部激活码
        </el-button>
        <el-button v-if="showType == 1" type="primary" @click="closeDialogByBtn" style="margin-bottom: -15px; margin-top: 10px;">
          关闭
        </el-button>
      </div>
  </el-dialog>
</template>
  
<script>
import useClipboard from "vue-clipboard3";
import { useRouter } from "vue-router";
import { ref } from 'vue';
import { ElMessage } from 'element-plus'
import $ from "jquery";
import { gotoLogin } from '../Utils/gotoLogin';
import base_url from '../Utils/BaseUrl'

export default {
  name:'CreateCode',
  components: {
  },
  setup(props,context) {

    // vue-router
    const router = useRouter();

    // 拷贝
    const { toClipboard } = useClipboard();

    // 后端api
    const api = {
      active_code: base_url + 'admin/createActiveCode/',
    }

    // 控制Dialog显示
    const DialogVisilble = ref(false);
    const err_msg = ref('');
    // 显示类型
    const showType = ref(0);
    const ActiveParam = ref({});

    const activeCodeList = ref([]);

    // 批量创建激活码
    const createCode = () => {
      // 校验参数
      err_msg.value = '';
      if(ActiveParam.value.validType != 365 && ActiveParam.value.validType != 7 && 
        ActiveParam.value.validType != 30 && ActiveParam.value.validType != -1) {
          err_msg.value = '请选择激活码有效期';
          return;
      }
      if(ActiveParam.value.codeType == null 
      || (ActiveParam.value.codeType !=0 && ActiveParam.value.codeType !=1)) {
          err_msg.value = '请选择激活码类型';
          return;
      }
      if(ActiveParam.value.count == null 
        || (ActiveParam.value.count != 1 && ActiveParam.value.count != 5 && ActiveParam.value.count != 10)) {
          err_msg.value = '请选择激活码数量';
          return;
      }

      $.ajax({
        url: api.active_code,
        type:'post',
        data:{
          validType:ActiveParam.value.validType,
          codeType:ActiveParam.value.codeType,
          sizeType:ActiveParam.value.sizeType,
          count:ActiveParam.value.count,
        },
        headers: {
          pantoken: localStorage.getItem('pantoken'),
        },
        success(resp){
          ElMessage({
            showClose: true,
            message: resp.info,
            type: resp.status,
          })
          if(resp.status == 'success') {
            err_msg.value = '';
            activeCodeList.value = resp.data.activeCodeList;
            showType.value = 1;
            context.emit('doAdminGetCodeList');
          }
          gotoLogin(router,resp);
        }
    });
    }

    // 复制
    const doCopy = async (content) => {
      await toClipboard(
        content
      );
      ElMessage({
        showClose: true,
        message: '复制成功',
        type: 'success',
      })
    }

    // 复制全部
    const copyAll = async () => {
      let content = '';
      for(let i=0;i<activeCodeList.value.length;i++) {
        content += activeCodeList.value[i] + '\n';
      }
      await toClipboard(
        content
      );
      ElMessage({
        showClose: true,
        message: '激活码已全部复制',
        type: 'success',
      })
    }

    // 关闭
    const closeDialog = (done) => {
      err_msg.value = '';
      showType.value = 0;
      ActiveParam.value = {};
      activeCodeList.value = [];
      done();
    }

    // 手动关闭
    const closeDialogByBtn = () => {
      err_msg.value = '';
      showType.value = 0;
      ActiveParam.value = {};
      activeCodeList.value = [];
      DialogVisilble.value = false;
    }

    return {
      DialogVisilble,
      err_msg,
      closeDialog,
      showType,
      ActiveParam,
      activeCodeList,
      router,
      api,
      createCode,
      closeDialogByBtn,
      doCopy,
      copyAll,

    
    }
  }

}
</script>

<style scoped>
body {
  font-size: 13px;
}
  
.item-class {
  font-size: 13px;
  color: #606266;
}

.errInfo{
  font-size: 12px;
  color: rgb(234, 80, 80);
  margin-top: 5px;
}

.form-class {
  padding: 0 10px;
  margin: 15px 0;
  white-space: nowrap; 
  overflow: hidden;
  text-overflow:ellipsis;
}
  
  
</style>