<template>
  <span>
    <img :src="imgUrl" alt="文件" style="width: 30px;" />
  </span>

</template>

<script>
import { ref,computed } from 'vue';

export default {
  name:'FileIcon',
  components: {
  },
  props:['file_type'],
  setup(props) {

    const fileTypeMap = {
      0: { desc: "目录", icon: "folder" },
      1: { desc: "视频", icon: "video" },
      2: { desc: "音频", icon: "music" },
      3: { desc: "图片", icon: "image" },
      4: { desc: "exe", icon: "pdf" },
      5: { desc: "doc", icon: "word" },
      6: { desc: "excel", icon: "excel" },
      7: { desc: "纯文本", icon: "txt" },
      8: { desc: "程序", icon: "code" },
      9: { desc: "压缩包", icon: "zip" },
      10: { desc: "其他文件", icon: "others" },
    };

    let now_icon = computed(() => {
      return fileTypeMap[props.file_type].icon
    })
    const getImage = () => {
      return "https://kkbpro-pan-image.oss-cn-shanghai.aliyuncs.com/icon-image/" + now_icon.value + ".png" ;
    }
    const imgUrl = ref(getImage());


    return {
      fileTypeMap,
      now_icon,
      getImage,
      imgUrl,
      
    }
  }

}
</script>

<style scoped>

</style>