<template>
  <div class="global">
    <div class="content">
      <div class="bar">
        <el-button type="success" class="btn-style" :disabled="isShowBtn" @click="revertBlock.DialogVisilble = true" >
          <span class="iconfont icon-revert"></span>还原
        </el-button>
        <el-button type="danger" class="btn-style" :disabled="isShowBtn" @click="deepDelBlock.DialogVisilble = true" >
          <span class="iconfont icon-del"></span>彻底删除
        </el-button>
      </div>
      <div class="body">
        <DirShow :dir="dir" ></DirShow>
      </div>
      <div v-if="dataSource.totalCount" >
        <TableRecycle :dataSource="dataSource"
         @changeSize="changeSize" @changeNo="changeNo" 
         @updateSelection="updateSelection"
         @doOneRevert="doOneRevert" @doOneDeepDel="doOneDeepDel" >
        </TableRecycle>
      </div>
      <div v-else>
        <NoData :msg="noDataMsg">
          <template v-slot:forMid>
            <div style="height: 15vh;"></div>
          </template>
        </NoData>
      </div>
    </div>
  </div>

  <TipsBlock ref="revertBlock" :msg="revertMsg" @confirmReCall="doRevert" ></TipsBlock>
  <TipsBlock ref="deepDelBlock" :msg="deepDelMsg" @confirmReCall="doDeepDel" ></TipsBlock>

</template>

<script>
import TableRecycle from "../../components/TableRecycle";
import { ref,computed } from 'vue';
import $ from "jquery";
import { ElMessage } from 'element-plus'
import { useRouter } from 'vue-router'
import { gotoLogin } from '../../Utils/gotoLogin';
import NoData from '../../components/NoData';
import DirShow from "../../components/DirShow";
import { useStore } from "vuex";
import TipsBlock from '../../components/TipsBlock'
import base_url from '../../Utils/BaseUrl'

export default {
  name:'RecycleBlock',
  components: {
    TableRecycle,
    NoData,
    DirShow,
    TipsBlock,
  },
  setup() {

    // vuex
    const store = useStore();
    // vue-router
    const router = useRouter();

    const noDataMsg = ref('暂无相关数据');
    const revertMsg = ref('确定要还原这些文件吗？');
    const deepDelMsg = ref('确定要彻底删除选中的文件吗? 删除将无法恢复');

    const dir = ref('/');

    const api = {
      get_recycle_list: base_url + 'recycle/getAll/',
      do_revert:base_url + 'recycle/revert/',
      do_deep_del:base_url + 'recycle/deepDal/',
    }

    // 表格信息
    const dataSource = ref({
      list:[],
      totalCount:0,
      nowPage:1,
      pageSize:15,
    });
    // 获取回收站文件列表
    const getRecycleFileList = (nowPage, pageSize) => {
      $.ajax({
        url: api.get_recycle_list,
        type:'get',
        data:{
          nowPage:nowPage,
          pageSize:pageSize,
        },
        headers: {
          pantoken: localStorage.getItem('pantoken'),
        },
        success(resp){
          if(resp.status == 'success') {
            dataSource.value = resp.data;
          } else {
            ElMessage({
              showClose: true,
              message: resp.info,
              type: resp.status,
            })
          }
          gotoLogin(router,resp);
        }
      });
    }
    const doGetRecycleFileList = () => {
      dataSource.value.list = [];
      getRecycleFileList(dataSource.value.nowPage,dataSource.value.pageSize);
      // 更新网盘空间
      store.dispatch('doUpdateSpaceByToken',function(resp) {
        if(resp.status != 'success') {
          ElMessage({
            showClose: true,
            message: resp.info,
            type: resp.status,
          })
          router.push({name:'LoginView'});
        }
      })
    }
    doGetRecycleFileList();
    const changeSize = (size) => {
      dataSource.value.pageSize = size;
      doGetRecycleFileList();
    }
    const changeNo = (page) => {
      dataSource.value.nowPage = page;
      doGetRecycleFileList();
    }

    // 还原与删除展示
    const isShowBtn = ref(true);
    const changeShow = (show) => {
      isShowBtn.value = show;
    }
    const revertBlock = ref();
    const deepDelBlock = ref();

    // 选中处理
    const selectedList = ref([]);
    let selectedIdList = computed(() => {
      let arr = [];
      for(let i=0;i<selectedList.value.length;i++) arr.push(selectedList.value[i].file_id);
      return arr;
    });
    const updateSelection = (selection) => {
      selectedList.value = selection;
      changeShow(selectedList.value.length == 0);
    }

    // 还原
    const doRevert = () => {
      $.ajax({
        url: api.do_revert,
        type:'post',
        data:{
          files:selectedIdList.value,
        },
        headers: {
          pantoken: localStorage.getItem('pantoken'),
        },
        success(resp){
          ElMessage({
            showClose: true,
            message: resp.info,
            type: resp.status,
          })
          if(resp.status == 'success') {
            // 清空
            selectedList.value.length = 0;
          }
          doGetRecycleFileList();
          revertBlock.value.DialogVisilble = false;
          gotoLogin(router,resp);
        }
      });
    }
    const doOneRevert = (item) => {
      // 清空
      selectedList.value.length = 0;
      selectedList.value.push(item);
      revertBlock.value.DialogVisilble = true;
    }

    // 删除
    const doDeepDel = () => {
      $.ajax({
        url: api.do_deep_del,
        type:'post',
        data:{
          files:selectedIdList.value,
        },
        headers: {
          pantoken: localStorage.getItem('pantoken'),
        },
        success(resp){
          ElMessage({
            showClose: true,
            message: resp.info,
            type: resp.status,
          })
          if(resp.status == 'success') {
            // 清空
            selectedList.value.length = 0;
          }
          doGetRecycleFileList();
          deepDelBlock.value.DialogVisilble = false;
          gotoLogin(router,resp);
        }
      });
    }
    const doOneDeepDel = (item) => {
      // 清空
      selectedList.value.length = 0;
      selectedList.value.push(item);
      deepDelBlock.value.DialogVisilble = true;
    }


    return {
      noDataMsg,
      selectedList,
      selectedIdList,
      updateSelection,
      changeSize,
      changeNo,
      doRevert,
      doDeepDel,
      dataSource,
      doGetRecycleFileList,
      isShowBtn,
      changeShow,
      dir,
      doOneRevert,
      doOneDeepDel,
      revertBlock,
      deepDelBlock,
      revertMsg,
      deepDelMsg,


    }
  }

}
</script>

<style scoped>
.btn-style{
  font-size: 12px;
  height: 30px;
}

.global{
  margin: 0 15px;
  width: calc(75vw);
}

.bar {
  display: flex;
  align-items: center;
  margin: 15px 0;
  margin-bottom: 10px;
}

.iconfont{
  font-size: 13px;
}

.input-style {
  width: 25vw;
  margin: 0 8px;
  margin-left: 15px;
  font-size: 13px;
}

.body {
  font-size: 13px;
  font-weight: bold;
  margin-bottom: 5px;
}

.no-data-item {
  background-color: #f1f1f1;
  width: 8vw;
  height: 20vh;
  margin-top: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 13px;
  cursor: pointer;
}

</style>