<template>
  <div class="global">
    <div class="title">
      <span>上传任务</span>
      <span class="tips">（仅展示本次上传任务）</span>
    </div>
    <div class="file-list">
      <div v-if="fileList.length > 0" style="min-height: 50vh;">
        <div v-for="item in fileList" class="file-item" :key="item.uid" >
          <div class="text">
            {{ item.fileName }}
          </div>
          <div style="display: flex; align-items: center;">
            <div>
              <div class="progress">
            <!--上传-->
            <el-progress
              :percentage="item.uploadProgress"
              v-if="
                item.status == STATUS.uploading.value ||
                item.status == STATUS.upload_seconds.value ||
                item.status == STATUS.upload_finish.value
              "
            />
              </div>
              <div class="upload-status">
            <!--图标-->
            <span
              :class="['iconfont', 'icon-' + STATUS[item.status].icon]"
              :style="{ color: STATUS[item.status].color }"
            ></span>
            <span style="display: inline-block; width: 3px;"></span>
            <!--状态描述-->
            <span
              class="status"
              :style="{ color: STATUS[item.status].color }"> 
              {{ item.status == "fail" ? item.errorMsg : STATUS[item.status].desc }}</span>
            <!--上传中-->
            <span
              class="upload-info"
              v-if="item.status == STATUS.uploading.value"
            >
              {{ sizeFormat(item.uploadSize) }} / {{ sizeFormat(item.totalSize) }}
            </span>
              </div>
            </div>
            <div style="flex: 1;"></div>
            <div class="op">
            <!--MD5-->
              <el-progress
              type="circle"
              :width="45"
              :percentage="item.md5Progress"
              v-if="item.status == STATUS.init.value"
              />
              <div class="op-btn">
                <!-- 上传 -->
                <div @click="doContinue(item.uid)" class="op-btn-item" v-if="item.pause == true && item.status === STATUS.uploading.value">
                  <img src="../assets/icon-image/upload.png" alt="上传" style="width: 30px;">
                </div>
                <!-- 暂停 -->
                <div @click="doPause(item.uid)" class="op-btn-item" v-if="item.pause == false && item.status === STATUS.uploading.value">
                  <img src="../assets/icon-image/pause.png" alt="暂停" style="width: 30px;">
                </div>
                <!-- 删除 -->
                <div @click="doDelete(item.uid)" class="op-btn-item" v-if="
                  item.status != STATUS.init.value &&
                  item.status != STATUS.upload_finish.value &&
                  item.status != STATUS.upload_seconds.value">
                  <img src="../assets/icon-image/del.png" alt="删除" style="width: 30px;">
                </div>
                <!-- 清除 -->
                <div @click="doClean(item.uid)" class="op-btn-item" v-if="item.status == STATUS.upload_finish.value || item.status == STATUS.upload_seconds.value">
                  <img src="../assets/icon-image/clean.png" alt="清除" style="width: 30px;">
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-else style="min-height: 50vh;">
        <NoData msg="暂无上传任务" >
          <template v-slot:forMid>
            <div style="height: 8vh;"></div>
          </template>
        </NoData>
      </div>
    </div>
  </div>
</template>

<script>
import { ref,computed } from 'vue';
import NoData from '../components/NoData';
import SparkMD5 from "spark-md5";
import { useRouter,useRoute } from "vue-router";
import $ from "jquery";
import { gotoLogin } from '../Utils/gotoLogin';
import { size2Str } from '../Utils/SizeUtil'
import base_url from '../Utils/BaseUrl'
import { ElMessage } from 'element-plus'

export default {
  name:'UploadBlock',
  components: {
    NoData,

  },
  setup(props,context) {

    // 空间格式化
    const sizeFormat = size2Str;

    // vue-router
    const router = useRouter();
    const route = useRoute();
    const pathBegin = computed(() => {
      let index = route.fullPath.lastIndexOf("/");
      return route.fullPath.substr(0,index);
    });
    const pathEnd = computed(() => {
      let index = route.fullPath.lastIndexOf("/");
      return route.fullPath.substr(index+1);
    });


    // 后端api
    const api = {
      upload:base_url + 'file/chunkUpload/',

    }

    // 文件状态数组
    const STATUS = {
      emptyfile: {
        value: "emptyfile",
        desc: "文件为空",
        color: "#F75000",
        icon: "close",
      },
      fail: {
        value: "fail",
        desc: "上传失败",
        color: "#F75000",
        icon: "close",
      },
      init: {
        value: "init",
        desc: "解析中",
        color: "#e6a23c",
        icon: "clock",
      },
      uploading: {
        value: "uploading",
        desc: "上传中",
        color: "#409eff",
        icon: "upload",
      },
      upload_finish: {
        value: "upload_finish",
        desc: "上传完成",
        color: "#67c23a",
        icon: "ok",
      },
      upload_seconds: {
        value: "upload_seconds",
        desc: "秒传",
        color: "#67c23a",
        icon: "ok",
      },
    };

    // 文件信息列表
    const fileList = ref([]);
    const delList = ref([]);

    // 每一片大小
    const chunkSize = 1024 * 117;   // 370kB

    // 计算文件md5
    const computeMD5 = (fileItem) => {
      let file = fileItem.file;
      let blobSlice =
          File.prototype.slice ||
          File.prototype.mozSlice ||
          File.prototype.webkitSlice;
      let chunks = Math.ceil(file.size / chunkSize);
      let currentChunk = 0;
      let spark = new SparkMD5.ArrayBuffer();
      let fileReader = new FileReader();

    let loadNext = () => {
        let start = currentChunk * chunkSize;
        let end = start + chunkSize >= file.size ? file.size : start + chunkSize;
        fileReader.readAsArrayBuffer(blobSlice.call(file, start, end));
    };

    loadNext();
    return new Promise((resolve) => {
      let resultFile = getFileByUid(file.uid);
      fileReader.onload = (e) => {
          spark.append(e.target.result); // Append array buffer
          currentChunk++;
          if (currentChunk < chunks) {
            let percent = Math.floor((currentChunk / chunks) * 100);
            resultFile.md5Progress = percent;
            loadNext();
          }
          // MD5完成
          else {
            let md5 = spark.end();
            spark.destroy();          //释放缓存
            resultFile.md5Progress = 100;
            resultFile.status = STATUS.uploading.value;
            resultFile.md5 = md5;
            resolve(fileItem.uid);
          }
      };
      fileReader.onerror = () => {
        resultFile.md5Progress = -1;
        resultFile.status = STATUS.fail.value;
        resolve(fileItem.uid);
      };
      }).catch(() => {
        return null;
      });

    }

    // 文件上传
    const uploadFile = async (file,dir) => {
      // 文件信息对象
      const fileItem = {
        file: file,
        // 文件UID
        uid: file.uid,
        // md5进度
        md5Progress: 0,
        // md5值
        md5: null,
        //文件名
        fileName: file.name,
        // 上传状态
        status: STATUS.init.value,
        // 已上传大小
        uploadSize: 0,
        // 文件总大小
        totalSize: file.size,
        // 进度
        uploadProgress: 0,
        // 暂停
        pause: false,
        // 当前分片
        chunkIndex: 0,
        // 所属目录
        dir: dir,
        // 错误信息
        errorMsg: '',
      };
      // 加入文件列表
      fileList.value.unshift(fileItem);
      // 校验文件非空
      if(fileItem.totalSize == 0) {
        fileItem.status = STATUS.emptyfile.value;
        return;
      }
      let md5FileUid = await computeMD5(fileItem);
      if (md5FileUid == null) return;
      await doUpload(md5FileUid);

    }

    // 分片上传
    const doUpload = async (uid) => {
      // 进行分片
      let currentFile = getFileByUid(uid);
      let chunkIndex = currentFile.chunkIndex;
      const file = currentFile.file;
      const fileSize = currentFile.totalSize;
      const chunks = parseInt(Math.ceil(fileSize / chunkSize));
      // 网盘空间不足
      let isFull = ref(false);
      // 秒传
      let fastUpload = ref(false);

      for (let i = chunkIndex; i < chunks; i++) {
        // 校验
        if(isFull.value == true) break;
        if(fastUpload.value == true) break;
        // 删除操作
        let delIndex = delList.value.indexOf(uid);
        if (delIndex != -1) {
          delList.value.splice(delIndex, 1);
          break;
        }
        currentFile = getFileByUid(uid);
        if (currentFile.pause) break;

        // 上传逻辑
        let start = i * chunkSize;
        let end = start + chunkSize >= fileSize ? fileSize : start + chunkSize;
        // 向后端发送请求
        let chunkFile = file.slice(start, end);
        let formData = new FormData();
        formData.append('file',chunkFile);
        formData.append('dir',currentFile.dir);
        formData.append('chunkIndex',i);
        formData.append('md5',currentFile.md5);
        formData.append('fileName',currentFile.fileName);
        formData.append('chunkTotal',chunks);
        formData.append('fileTotalSize',currentFile.totalSize);
        formData.append('fileUid',currentFile.uid);

        await $.ajax({
          url: api.upload,
          type:'post',
          data: formData,
          contentType : false,
          processData : false,
          // 覆盖全局Loading配置
          beforeSend: function() {},
          headers: {
            pantoken: localStorage.getItem('pantoken'),
          },
          success(resp){
            // 失败异常
            if(resp.code == 500) {
              ElMessage({
                showClose: true,
                message: resp.info,
                type: resp.status,
              });
              return;
            }
            // 网盘空间不足
            if(resp.code == 451) {
              isFull.value = true;
              currentFile.status = STATUS.fail.value;
              currentFile.errorMsg = resp.info;
              return;
            }
            // 秒传
            if(resp.code == 452) {
              fastUpload.value = true;
              currentFile.uploadSize = currentFile.totalSize;
              currentFile.chunkIndex = chunks;
              currentFile.uploadProgress = 100;
              currentFile.status = STATUS.upload_seconds.value;
              if(pathBegin.value == "/home") context.emit('doGetFileList');
              return;
            }
            // 更新上传进度
            if(resp.status == 'success') {
              currentFile.uploadSize = currentFile.uploadSize + end - start;
              currentFile.chunkIndex = i + 1;
              currentFile.uploadProgress = Math.floor((currentFile.uploadSize / fileSize) * 10000) / 100;
              if(i + 1 == chunks) {
                currentFile.status = STATUS.upload_finish.value;
                currentFile.uploadProgress = 100;
                if(pathBegin.value == "/home") context.emit('doGetFileList');
              }
            }
            gotoLogin(router,resp);
          },
          // 覆盖全局Loading配置
          complete: function() {}
        });


      }

    }

    //获取文件
    const getFileByUid = (uid) => {
      let file = fileList.value.find((item) => {
        return item.file.uid === uid;
      });
      return file;
    };

    // 按钮操作
    const doPause = (uid) => {
      let item = getFileByUid(uid);
      item.pause = true;
    }
    const doContinue = (uid) => {
      let item = getFileByUid(uid);
      item.pause = false;
      doUpload(uid);
    }
    const doClean = (uid) => {
      let item = getFileByUid(uid);
      for(let i=0;i<fileList.value.length;i++) {
        if(fileList.value[i].uid == item.uid) {
          fileList.value.splice(i, 1);
          break;
        }
      }
    }
    const doDelete = (uid) => {
      doClean(uid);
      delList.value.push(uid);
    }    


    return {
      fileList,
      uploadFile,
      STATUS,
      doUpload,
      sizeFormat,
      doPause,
      doContinue,
      doClean,
      doDelete,
      pathBegin,
      pathEnd,
      route,


    }
  }

}
</script>

<style scoped>
.global {
  overflow-x: hidden;
  overflow-y: scroll;
  max-height: 75vh;
}

.title {
  padding-bottom: 10px;
  border-bottom: 1px solid #e4e5e8;
}
.tips {
  font-size: 13px;
  color: rgb(169, 169, 169);
}

.file-item {
  font-size: 12px;
  padding: 5px 10px;
  border-bottom: 1px solid #e4e5e8;
}

.progress {
  height: 10px;
  margin-top: 2px;
  margin-bottom: 5px;
  min-width: 450px;
}

.upload-info {
  margin: 0 5px;
}

.op-btn {
  display: flex;
  align-items: center;
}

.op-btn-item {
  margin: 0 5px;
  margin-bottom: 18px;
  cursor: pointer;
}



</style>
  