<template>
  <el-dialog
    v-model="DialogVisilble"
    :before-close="closeDialog"
    title="移动到"
    width="50%"
  >
    <div style="margin-top: -15px;"></div>
    <div class="item-class">
        <div class="title" >
          <DirShow :dir="move_dir" @changeDir="changeDir" ></DirShow>
        </div>
        <div class="dir-list">
            <div v-if="moveDirList.length != 0">
                <div v-for="item in moveDirList" :key="item.file_id" @click="changeDir(move_dir + item.file_name + '/')">
                  <div class="folder-item" >
                    <FileIcon :file_type="item.folder_type" ></FileIcon>
                    <div style="margin: 0 10px;">{{ item.file_name }}</div>
                  </div>
                </div>
            </div>
            <div v-else>
              <NoData :msg="noDataMsg"></NoData>
            </div>
        </div>
    </div>
    <div style="margin-bottom: 15px;"></div>
    <div style="display: flex; border-top: 1px solid #f1f2f4;">
      <div style="flex: 1;"></div>
      <el-button type="primary" @click="doFileMove" style="margin-bottom: -15px; margin-top: 10px;">
        移动
      </el-button>
    </div>
  </el-dialog>
</template>

<script>
import DirShow from "../components/DirShow";
import { ref,watch } from 'vue';
import { gotoLogin } from '../Utils/gotoLogin';
import { useRouter } from 'vue-router'
import $ from "jquery";
import { ElMessage } from 'element-plus'
import FileIcon from './FileIcon';
import NoData from '../components/NoData';
import base_url from '../Utils/BaseUrl'


export default {
  name:'MoveFile',
  components: {
    DirShow,
    FileIcon,
    NoData,
  },
  setup(props,context) {
    // vue-router
    const router = useRouter();

    // 后端api
    const api = {
      get_all_folder: base_url + 'file/getAllFolder/',
    }

    // 移动目录预览
    const move_dir = ref('/');
    const moveDirList = ref([]);
    // 获取当前目录下的所有子目录
    const getAllFolder = () => {
        $.ajax({
        url: api.get_all_folder,
        type:'get',
        data:{
          dir:move_dir.value,
        },
        headers: {
          pantoken: localStorage.getItem('pantoken'),
        },
        success(resp){
          if(resp.status == 'success') {
            moveDirList.value = resp.data.folderList;
          } 
          else {
            ElMessage({
              showClose: true,
              message: resp.info,
              type: resp.status,
            })
          }
          gotoLogin(router,resp);
        }
      });
    }
    const changeDir = (new_dir) => {
        move_dir.value = new_dir;
    }
    watch(move_dir,() => {
        getAllFolder();
    },{ immediate: true, deep: true })

    // 控制Dialog显示
    const DialogVisilble = ref(false);
    watch(DialogVisilble,() => {
        getAllFolder();
    },{ immediate: true, deep: true })

    // 移动文件
    const doFileMove = () => {
      context.emit('doFileMove',move_dir.value);
    }

    const noDataMsg = ref('移动到此目录下')

    // 关闭
    const closeDialog = (done) => {
      move_dir.value = '/';
      moveDirList.value = [];
      done();
    }

    return {
        DialogVisilble,
        closeDialog,
        move_dir,
        moveDirList,
        changeDir,
        doFileMove,
        getAllFolder,
        noDataMsg,

    }
  }

}
</script>

<style scoped>
.title {
  background-color: #efefef;
  padding: 8px 10px;
  font-size: 13px;
  white-space: nowrap; 
  overflow: hidden;
  text-overflow:ellipsis;
  margin-bottom: 10px;
}

.dir-list {
  height: 30vh;
  overflow-y: scroll;
}

.folder-item {
  display: flex;
  align-items: center;
  padding: 5px 10px;
  border-bottom: 1px solid #e9eaed;
  cursor: pointer;
}

.folder-item:hover {
  background-color: #f3f3f3;
}

</style>