<template>
  <div class="global">
    <div class="header">
      <img style="cursor: pointer; width: 45px;" @click="toLogin" src="../../assets/logo-share.png" class="logoimg" alt="kk网盘" >
      <div style="cursor: pointer;" @click="toLogin" class="logofont" > kk网盘 </div>
    </div>
    <div class="share-info">
      <div><el-avatar :src="shareInfo.user_image" :size="50" /></div>
      <div style="margin-right: 15px;"></div>
      <div style="display: flex; flex-direction: column;">
        <div style="display: flex; align-items: start;">
          <!-- vip 管理员标识 -->
          <div v-if="shareInfo.user_state == 2">
            <img src="../../assets/vip.png" alt="VIP" style="width: 20px; margin-top: -2px;">
          </div>
          <div v-if="shareInfo.user_state == -1" style="">
            <img src="../../assets/管理员.png" alt="管理员" style="width: 35px; margin-top: -2px;">
          </div>
          <!-- 昵称 -->
          <div style="margin-left: 5px;"> {{ shareInfo.user_name }} </div>
          <div style="margin-left: 15px;">分享于&nbsp;{{ format(shareInfo.create_time) }}</div>
        </div>
        <div style="height: 5px;"></div>
        <div style="display: flex;">
          分享文件：{{ shareInfo.file_name }}
        </div>
      </div>
      <div style="flex: 1;"></div>
      <div>
        <el-button v-if="store.state.is_login == true && store.state.userInfo.user_id == shareInfo.user_id"
         type="primary" style="margin-left: 10px;" @click="tipsBlockRef.DialogVisilble = true" >
          <span class="iconfont icon-cancel" style="font-size: 12px;">取消分享</span>
        </el-button>
        <el-button v-else
         type="primary" style="margin-left: 10px;" @click="saveMyDisk" :disabled="isShowBtn" >
          <span class="iconfont icon-import"  style="font-size: 12px;">&nbsp; 保存到我的网盘</span>
        </el-button>

      </div>
    </div>
    <div class="body">
      <DirShow :dir="dir" @changeDir="dirShowChangeDir"></DirShow>
    </div>
    <div v-if="dataSource.totalCount" class="table-class" >
      <TableWebShare :dir="dir" :dataSource="dataSource" :shareInfo="shareInfo"
        @changeSize="changeSize" @changeNo="changeNo" 
        @changeDir="tableChangeDir"  @updateSelection="updateSelection"
        @toDownload="toDownload" @tableSave="tableSave" >
      </TableWebShare>
    </div>
    <div v-else>
      <NoData :msg="noDataMsg">
        <template v-slot:forMid>
          <div style="height: 15vh;"></div>
        </template>
      </NoData>
    </div>
  </div>

  <DownloadBlock ref="downloadBlockRef" @confirmReCall="doDownloadFile"></DownloadBlock>
  <TipsBlock ref="tipsBlockRef" @confirmReCall="cancelShare" :msg="tipsMsg" ></TipsBlock>

</template>

<script>
import { ref,computed } from 'vue';
import { useRoute,useRouter } from "vue-router";
import { ElMessage } from 'element-plus'
import { formatDate } from '../../Utils/FormatDate';
import $ from "jquery";
import DirShow from '../../components/DirShow'
import { useStore } from "vuex";
import TableWebShare from '../../components/TableWebShare'
import NoData from '../../components/NoData'
import { gotoLogin } from '../../Utils/gotoLogin';
import DownloadBlock from '../../components/DownlaodBlock';
import TipsBlock from '../../components/TipsBlock'
import base_url from '../../Utils/BaseUrl'

export default {
  name:'WebShareBlock',
  components: {
    DirShow,
    TableWebShare,
    NoData,
    DownloadBlock,
    TipsBlock,
  },
  setup() {

    // 时间格式化
    const format = formatDate;

    // vue-x
    const store = useStore();

    // 路由
    const router = useRouter();
    const route = useRoute();


    const shareId = ref(route.params.shareId);
    const err_msg = ref('');
    const shareInfo = ref({});

    // 路径
    const dir = ref('/');
    const dirIdList = ref([]);
    const now_dirId = ref('');

    // 后端api
    const api = {
      get_shareInfo:base_url + 'webShare/getInfo/',
      get_web_share_list:base_url + 'webShare/getList/',
      save_myDisk:base_url + 'webShare/save/',
      download:base_url + 'webShare/download/',
      cancel_share:base_url + 'webShare/cancelShare/',
    }

    const noDataMsg = ref('暂无数据');
    const tipsMsg = ref('确定要取消分享此文件吗?');

    // 去登陆
    const toLogin = () => {
      if(store.state.is_login == true) return;
      router.push({name:'LoginView'})
    }

    // 获取分享信息
    const getShareInfo = () => {
      $.ajax({
        url: api.get_shareInfo,
        type:'get',
        data:{
          share_id:shareId.value,
        },
        success(resp){
          if(resp.status == 'success') {
            shareInfo.value = resp.data.shareInfo;
            dirIdList.value.push(resp.data.shareInfo.pid);
            now_dirId.value = resp.data.shareInfo.pid;
            dogetWebShareFileList();
          } 
          else if(resp.code >= 602 && resp.code <= 606) {
            router.push({
              name:'ShareNotFound',
              query:{
                code:resp.code
              }
            });
          }
        }
      });
    }
    getShareInfo();

    // 分享登录
    const shareLoginByToken = () => {
      if(localStorage.getItem('pantoken')) store.dispatch('doLoginByToken',function(){});
    }
    shareLoginByToken();


    // 表格相关
    // 表格信息
    const dataSource = ref({
      list:[],
      totalCount:0,
      nowPage:1,
      pageSize:15,
    });
    // 获取外部分享文件列表
    const getWebShareFileList = (nowPage, pageSize) => {
      $.ajax({
        url: api.get_web_share_list,
        type:'get',
        data:{
          pid: now_dirId.value,
          share_id: shareInfo.value.share_id,
          nowPage:nowPage,
          pageSize:pageSize,
        },
        success(resp){
          if(resp.status == 'success') {
            dataSource.value = resp.data;
          } else if(resp.code == 617 || resp.code == 618 ) {
            ElMessage({
              showClose: true,
              message: resp.info,
              type: resp.status,
            })
            router.push({
              name:'ShareCheckBlock',
              params:{
                shareId:shareId.value,
              }
            });
          } else if(resp.code >= 602 && resp.code <= 606) {
            router.push({
              name:'ShareNotFound',
              query:{
                code:resp.code,
              }
            });
          }
        }
      });
    }
    const dogetWebShareFileList = () => {
      dataSource.value.list = [];
      getWebShareFileList(dataSource.value.nowPage,dataSource.value.pageSize);
    }
    const changeSize = (size) => {
      dataSource.value.pageSize = size;
      dogetWebShareFileList();
    }
    const changeNo = (page) => {
      dataSource.value.nowPage = page;
      dogetWebShareFileList();
    }

    // 取消分享展示
    const isShowBtn = ref(true);
    const changeShow = (show) => {
      isShowBtn.value = show;
    }

    // 选中处理
    const selectedList = ref([]);
    let selectedIdList = computed(() => {
      let arr = [];
      for(let i=0;i<selectedList.value.length;i++) arr.push(selectedList.value[i].file_id);
      return arr;
    });
    const updateSelection = (selection) => {
      selectedList.value = selection;
      changeShow(selectedList.value.length == 0);
    }

    // 保存到我的网盘
    const saveMyDisk = () => {
      if(store.state.is_login != true) router.push({name:'LoginView'});
      $.ajax({
        url: api.get_shareInfo,
        type:'post',
        data:{
          share_id:shareId.value,
        },
        headers: {
          pantoken: localStorage.getItem('pantoken'),
        },
        success(resp){
          if(resp.status == 'success') {
            ElMessage({
              showClose: true,
              message: resp.info,
              type: resp.status,
            })
          } 
          else if(resp.code >= 602 && resp.code <= 606) {
            router.push({
              name:'ShareNotFound',
              query:{
                code:resp.code
              }
            });
          }
          else if(resp.code == 617 || resp.code == 618 ) {
            ElMessage({
              showClose: true,
              message: resp.info,
              type: resp.status,
            })
            router.push({
              name:'ShareCheckBlock',
              params:{
                shareId:shareId.value,
              }
            });
          }
          if(resp.code == 450) {
            ElMessage({
              showClose: true,
              message: resp.info,
              type: resp.status,
            })
          }
          gotoLogin(router,resp);
        }
      });
    }
    const tableSave = (item) => {
      selectedList.value.length = 0;
      selectedList.value.push(item);
      saveMyDisk();
    }

    // 下载
    const downloadBlockRef = ref();
    const doDownloadFile = (file_id) => {
      $.ajax({
        url: api.download,
        type:'get',
        data:{
          file_id:file_id,
          share_id:shareId.value,
        },
        success(resp){
          if(resp.status == 'success') {
            downloadBlockRef.value.DialogVisilble = false;
            // 下载
            const a = document.createElement('a')
            a.href = resp.data.url
            // a.download = res.success.fileName // 下载后文件名
            a.style.display = 'none'
            document.body.appendChild(a);
            a.click() // 点击下载
            document.body.removeChild(a) // 下载完成移除元素
          }
          else if(resp.code >= 602 && resp.code <= 606) {
            router.push({
              name:'ShareNotFound',
              query:{
                code:resp.code
              }
            });
          }
          else if(resp.code == 617 || resp.code == 618 ) {
            ElMessage({
              showClose: true,
              message: resp.info,
              type: resp.status,
            })
            router.push({
              name:'ShareCheckBlock',
              params:{
                shareId:shareId.value,
              }
            });
          }
        }
      });
    }
    const toDownload = (item) => {
      downloadBlockRef.value.file_name = item.file_name;
      downloadBlockRef.value.file_id = item.file_id;
      downloadBlockRef.value.DialogVisilble = true;
    }

    // 取消分享
    const tipsBlockRef = ref();
    const cancelShare = () => {
      if(store.state.is_login != true) router.push({name:'LoginView'});
      $.ajax({
        url: api.cancel_share,
        type:'post',
        data:{
          share_id:shareId.value,
        },
        headers: {
          pantoken: localStorage.getItem('pantoken'),
        },
        success(resp){
          if(resp.status == 'success') {
            ElMessage({
              showClose: true,
              message: resp.info,
              type: resp.status,
            })
            router.push('/myshare');
          } 
          else if(resp.code >= 602 && resp.code <= 606) {
            router.push({
              name:'ShareNotFound',
              query:{
                code:resp.code
              }
            });
          }
          else if(resp.code == 617 || resp.code == 618 ) {
            ElMessage({
              showClose: true,
              message: resp.info,
              type: resp.status,
            })
            router.push({
              name:'ShareCheckBlock',
              params:{
                shareId:shareId.value,
              }
            });
          }
          if(resp.code == 450) {
            ElMessage({
              showClose: true,
              message: resp.info,
              type: resp.status,
            })
          }
          gotoLogin(router,resp);
        }
      });
    }

    // 表格改变路径
    const tableChangeDir = (new_dir, file_id) => {
      dir.value = new_dir;
      dirIdList.value.push(file_id);
      now_dirId.value = file_id;
      dogetWebShareFileList();
    }

    // DirShow改变路径
    const dirShowChangeDir = (new_dir,index) => {
      dir.value = new_dir;
      for(let i=dirIdList.value.length-1;i>index;i--) dirIdList.value.pop();
      now_dirId.value = dirIdList.value[index];
      dogetWebShareFileList();
    }


    return {
      format,
      err_msg,
      shareInfo,
      dir,
      changeSize,
      changeNo,
      dirIdList,
      now_dirId,
      dataSource,
      getWebShareFileList,
      dogetWebShareFileList,
      updateSelection,
      selectedIdList,
      saveMyDisk,
      store,
      tableChangeDir,
      dirShowChangeDir,
      noDataMsg,
      isShowBtn,
      tableSave,
      toDownload,
      doDownloadFile,
      downloadBlockRef,
      cancelShare,
      tipsBlockRef,
      tipsMsg,
      toLogin,


    }
  }

}
</script>

<style scoped>
.global {
  height: calc(100vh);
}

.header {
  height: 45px;
  background-color: #0c95f7;
  padding-left: 15vw;
  color: white;
  display: flex; 
  align-items: center;
}

.logofont {
  margin-left: 10px;
  font-size: 23px;
  font-weight: bold;
}

.share-info {
  margin: 0 15vw;
  margin-top: 10px;
  border-bottom: 1px solid #e9eaed;
  height: 80px;
  display: flex;
  align-items: center;
  font-size: 12px;
  overflow: hidden;
}

.body {
  font-size: 13px;
  font-weight: bold;
  padding: 0 15vw;
  margin: 5px 0;
}

.table-class {
  padding: 0 15vw;
}

</style>
  