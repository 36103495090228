<template>
  <div class="gloal">
    <div class="table">
        <el-table
          ref="multipleTableRef"
          :data="dataSource.list"
          style="width: 100%; font-size: 13px;"
          @selection-change="handleSelectionChange"
          show-overflow-tooltip
      >
          <el-table-column type="selection">
          </el-table-column>
          <el-table-column property="code_content" label="激活码" width="0" min-width="22">
            <template #default="scope">
              <div style="display: flex; align-items: center;">
                <div>{{ scope.row.code_content }}</div>
                <div style="cursor: pointer; margin-left: 5px;" @click="doCopy(scope.row.code_content)"><el-icon size="15"><DocumentCopy /></el-icon></div>
              </div>
            </template>
          </el-table-column>
          <el-table-column property="create_time" label="创建时间" show-overflow-tooltip  width="0" min-width="16">
            <template #default="scope">
              {{ format(scope.row.create_time) }}
            </template>
          </el-table-column>
          <el-table-column property="valid_time" label="有效期" show-overflow-tooltip  width="0" min-width="16">
            <template #default="scope">
              <div v-if="scope.row.valid_time != null">{{ format(scope.row.valid_time) }}</div>
              <div v-else>永久</div>
            </template>
          </el-table-column>
          <el-table-column property="type" label="类型" show-overflow-tooltip  width="0" min-width="8">
            <template #default="scope">
              <div class="a-link" v-if="scope.row.type == 1">扩容{{ scope.row.size }}GB</div>
              <div style="color: #edbb71;" v-if="scope.row.type == 0">升级VIP</div>
            </template>
          </el-table-column>
          <el-table-column property="status" label="状态" show-overflow-tooltip  width="0" min-width="8">
            <template #default="scope">
              <div style="color: #f56c6c;" v-if="scope.row.status == 1">已使用</div>
              <div style="color: #67c23a;" v-if="scope.row.status == 0">未使用</div>
            </template>
          </el-table-column>
          <el-table-column label="操作" show-overflow-tooltip  width="0" min-width="10">
            <template #default="scope">
              <div style="display: flex; align-items: center; cursor: pointer;">
                  <div style="color: #f56c6c;" @click="delOneCode(scope.row)">删除</div>
              </div>
            </template>
          </el-table-column>
      
        </el-table>
    </div>
    <!-- 分页 -->
    <div class="pagination">
      <el-pagination
          v-if="dataSource.totalCount"
          :current-page="dataSource.nowPage"
          :page-size="dataSource.pageSize"
          :page-sizes="[15, 30, 50, 100]"
          small
          background
          layout="total, sizes, prev, pager, next, jumper"
          :total="dataSource.totalCount"
          @size-change="handlePageSizeChange"
          @current-change="handlePageNoChange"
          style="position: absolute; right: 10px; font-size: 13px;"
      />
    </div>
  </div>
</template>

<script>
import useClipboard from "vue-clipboard3";
import { formatDate } from '../Utils/FormatDate';
import { ElMessage } from 'element-plus'

export default {
  name:'TableAdminCode',
  components: {
  },
  props:['dataSource'],
  setup(props,context) {

    // 时间格式化
    const format = formatDate;

    // 拷贝
    const { toClipboard } = useClipboard();

    // 分页处理
    const handlePageSizeChange = (size) => {
      context.emit('changeSize',size);
    }
    const handlePageNoChange = (page) => {
      context.emit('changeNo',page);
    }

    // 选中处理
    const handleSelectionChange = (selection) => {
      context.emit('updateSelection',selection);
    }

    // 复制
    const doCopy = async (content) => {
      await toClipboard(
        content
      );
      ElMessage({
        showClose: true,
        message: '复制成功',
        type: 'success',
      })
    }

    const delOneCode = (item) => {
      context.emit('delOneCode',item)
    }

    


    return {
      format,
      handlePageSizeChange,
      handlePageNoChange,
      doCopy,
      handleSelectionChange,
      delOneCode,

    }
  },

}
</script>

<style scoped>
.gloal {
  position: relative;
  height: 70vh;
}

.pagination {
  position: absolute;
  width: 100%;
  bottom: 10px;
  padding-top: 10px;
  border-top: 1px solid #e0e0e0;
}
.text {
  font-size: 13px;
  cursor: pointer;
}

.text:hover {
  color: #06a7ff;
}

.hover-style {
  margin-right: 8px;
  font-size: 13px;
  color: #06a7ff;
  cursor: pointer;
}

.table {
  height: 66vh;
  overflow-y: scroll;
  overflow-x: hidden;
}

.text-too-long {
  max-width: 12vw; 
  white-space: nowrap; 
  overflow: hidden;
  text-overflow:ellipsis;
}

</style>