<template>
  <div class="gloal">
    <div class="table">
      <el-table
        ref="multipleTableRef"
        :data="dataSource.list"
        style="width: 100%; font-size: 13px;"
        @selection-change="handleSelectionChange"
    >
        <el-table-column type="selection">
        </el-table-column>
        <el-table-column property="file_name" label="文件名" show-overflow-tooltip width="0" min-width="12" style="font-size: 12px;" >
          <template #default="scope">
            <div 
              @mouseenter="scope.row.isShow = true;"
              @mouseleave="scope.row.isShow = false;"
              style="display: flex; align-items: center;"
            >
              <div class="text" style="display: flex; align-items: center;">
                <div @click="showFileDetail(scope.row)">
                  <FileIcon :file_type="scope.row.file_type" ></FileIcon>
                </div>
                <div @click="showFileDetail(scope.row)" style="margin-right: 7px;"></div>
                <div @click="showFileDetail(scope.row)" class="text-too-long" v-if="!scope.row.isReName">
                  {{ scope.row.file_name }}
                </div>
                <div v-else>
                  <el-input size="small" @blur="scope.row.isReName = false;doReNameFile(scope.row)" v-model="scope.row.file_name" placeholder="请输入文件名" clearable />
                </div>
              </div>
              <div style="flex: 1;"></div>
              <div v-show="scope.row.isShow" style="display: flex;">
                <span class="iconfont icon-share1 hover-style" @click="doCraeteShareLink(scope.row)">分享</span>
                <span
                  class="iconfont icon-download hover-style"
                  @click="download(scope.row)"
                  v-if="scope.row.folder_type != 0"
                  >下载</span>
                <span class="iconfont icon-del hover-style" @click="delOneFile(scope.row)">删除</span>
                <span
                  class="iconfont icon-edit hover-style"
                  @click="scope.row.isReName = true"
                  >重命名</span>
                <span class="iconfont icon-move hover-style" @click="moveOneFile(scope.row)">移动</span>
              </div>
            </div>
          </template>
        </el-table-column>
        <el-table-column property="last_update_time" label="最近修改" width="0" min-width="4">
          <template #default="scope">
            {{ format(scope.row.last_update_time) }}
          </template>
        </el-table-column>
        <el-table-column property="file_size" label="大小" show-overflow-tooltip  width="0" min-width="3">
          <template #default="scope">
            {{ sizeFormat(scope.row.file_size) }}
          </template>
        </el-table-column>
        
    
      </el-table>
    </div>
    <!-- 分页 -->
    <div class="pagination">
        <el-pagination
            v-if="dataSource.totalCount"
            :current-page="dataSource.nowPage"
            :page-size="dataSource.pageSize"
            :page-sizes="[15, 30, 50, 100]"
            small
            background
            layout="total, sizes, prev, pager, next, jumper"
            :total="dataSource.totalCount"
            @size-change="handlePageSizeChange"
            @current-change="handlePageNoChange"
            style="position: absolute; right: 10px; font-size: 13px;"
        />
    </div>
  </div>
</template>

<script>
import FileIcon from './FileIcon';
import { size2Str } from '../Utils/SizeUtil'
import { formatDate } from '../Utils/FormatDate';

export default {
  name:'TableBlock',
  components: {
    FileIcon,
  },
  props:['dataSource','dir'],
  setup(props,context) {

    // 空间格式化
    const sizeFormat = size2Str;
    // 时间格式化
    const format = formatDate;

    // 文件预览
    const showFileDetail = (item) => {
      // 文件夹
      if(item.folder_type == 0) {
        context.emit('changeDir', props.dir + item.file_name + "/")
      }
    }

    // 分页处理
    const handlePageSizeChange = (size) => {
      context.emit('changeSize',size);
    }
    const handlePageNoChange = (page) => {
      context.emit('changeNo',page);
    }

    // 选中处理
    const handleSelectionChange = (selection) => {
      context.emit('updateSelection',selection);
    }

    // 移动单个文件
    const moveOneFile = (item) => {
      context.emit('moveOneFile',item);
    }

    // 重命名
    const doReNameFile = (item) => {
      context.emit('doReNameFile',item);
    }

    // 删除单个文件
    const delOneFile = (item) => {
      context.emit('delOneFile',item);
    }

    // 创建分享
    const doCraeteShareLink = (item) => {
      context.emit('doCraeteShareLink',item);
    }

    // 下载
    const download = (item) => {
      context.emit('toDownload',item);
    }

    return {
      sizeFormat,
      showFileDetail,
      format,
      handlePageSizeChange,
      handlePageNoChange,
      handleSelectionChange,
      moveOneFile,
      doReNameFile,
      delOneFile,
      doCraeteShareLink,
      download,

    }
  },

}
</script>

<style scoped>
.gloal {
  position: relative;
  height: 70vh;
}

.pagination {
  position: absolute;
  width: 100%;
  bottom: 10px;
  padding-top: 10px;
  border-top: 1px solid #e0e0e0;
}
.text {
  font-size: 13px;
  cursor: pointer;
}

.text:hover {
  color: #06a7ff;
}

.hover-style {
  margin-right: 8px;
  font-size: 13px;
  color: #06a7ff;
  cursor: pointer;
}

.table {
  height: 66vh;
  overflow-y: scroll;
  overflow-x: hidden;
}

.text-too-long {
  max-width: 18vw; 
  white-space: nowrap; 
  overflow: hidden; 
  text-overflow:ellipsis;
}

</style>