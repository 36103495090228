<template>
  <div class="global">
    <div class="content">
      <div class="bar">
        <el-button type="success" class="btn-style" @click="createCodeRef.DialogVisilble = true" >
          <span class="iconfont icon-folder-add"></span>生成激活码
        </el-button>
        <el-button type="danger" class="btn-style" :disabled="isShowBtn" @click="delCodeRef.DialogVisilble = true" >
          <span class="iconfont icon-del"></span>批量删除
        </el-button>
      </div>
      <div class="body">
        <DirShow :dir="dir" @changeDir="changeDir"></DirShow>
      </div>
      <div v-if="dataSource.totalCount" >
        <TableAdminCode :dataSource="dataSource"
         @changeSize="changeSize" @changeNo="changeNo" 
         @updateSelection="updateSelection" @delOneCode="delOneCode" >
        </TableAdminCode>
      </div>
      <div v-else>
        <NoData :msg="noDataMsg">
          <template v-slot:forMid>
            <div style="height: 15vh;"></div>
          </template>
        </NoData>
      </div>
    </div>
  </div>

  <TipsBlock ref="delCodeRef" :msg="delMsg" @confirmReCall="doDelCodes" ></TipsBlock>
  <CreateCode ref="createCodeRef" @doAdminGetCodeList="doAdminGetCodeList"></CreateCode>

</template>

<script>
import { ref,computed } from 'vue';
import $ from "jquery";
import { ElMessage } from 'element-plus'
import { useRouter } from 'vue-router'
import { gotoLogin } from '../../Utils/gotoLogin';
import TableAdminCode from '../../components/TableAdminCode'
import NoData from '../../components/NoData';
import DirShow from "../../components/DirShow";
import TipsBlock from '../../components/TipsBlock'
import CreateCode from '../../components/CreateCode'
import base_url from '../../Utils/BaseUrl'

export default {
  name:'AdminActive',
  components: {
    TableAdminCode,
    NoData,
    DirShow,
    TipsBlock,
    CreateCode,
  },
  setup() {

    // vue-router
    const router = useRouter();

    const dir = ref('ActiveCode');
    const noDataMsg = ref('暂无相关数据');

    // 后端api
    const api = {
      get_all_code:base_url + 'admin/getAllCode/',
      del_codes:base_url + 'admin/removeCodes/',
    }

    // 表格信息
    const dataSource = ref({
      list:[],
      totalCount:0,
      nowPage:1,
      pageSize:15,
    });

    // 管理员获取所有激活码列表
    const adminGetList = (nowPage, pageSize) => {
      $.ajax({
        url: api.get_all_code,
        type:'get',
        data:{
          nowPage:nowPage,
          pageSize:pageSize,
        },
        headers: {
          pantoken: localStorage.getItem('pantoken'),
        },
        success(resp){
          if(resp.status == 'success') {
            dataSource.value = resp.data;
          } else {
            ElMessage({
              showClose: true,
              message: resp.info,
              type: resp.status,
            })
          }
          gotoLogin(router,resp);
        }
      });
    }
    const doAdminGetCodeList = () => {
      dataSource.value.list = [];
      adminGetList(dataSource.value.nowPage,dataSource.value.pageSize);
    }
    doAdminGetCodeList();
    const changeSize = (size) => {
      dataSource.value.pageSize = size;
      doAdminGetCodeList();
    }
    const changeNo = (page) => {
      dataSource.value.nowPage = page;
      doAdminGetCodeList();
    }

    // 删除展示
    const isShowBtn = ref(true);
    const changeShow = (show) => {
      isShowBtn.value = show;
    }
    
    // 选中处理
    const selectedList = ref([]);
    let selectedIdList = computed(() => {
      let arr = [];
      for(let i=0;i<selectedList.value.length;i++) arr.push(selectedList.value[i].code_id);
      return arr;
    });
    const updateSelection = (selection) => {
      selectedList.value = selection;
      changeShow(selectedList.value.length == 0);
    }

    // 删除分享
    const delCodeRef = ref();
    const delMsg = ref('确定要删除这些激活码吗？');
    const doDelCodes = () => {
        $.ajax({
        url: api.del_codes,
        type:'post',
        data:{
          files:selectedIdList.value,
        },
        headers: {
          pantoken: localStorage.getItem('pantoken'),
        },
        success(resp){
          ElMessage({
            showClose: true,
            message: resp.info,
            type: resp.status,
          })
          if(resp.status == 'success') {
            delCodeRef.value.DialogVisilble = false;
            // 清空
            selectedList.value.length = 0;
            doAdminGetCodeList();
          }
          gotoLogin(router,resp);
        }
      });
    }
    const delOneCode = (item) => {
      // 清空
      selectedList.value.length = 0;
      selectedList.value.push(item);
      delCodeRef.value.DialogVisilble = true;
    }

    const createCodeRef = ref();

    return {
      isShowBtn,
      selectedList,
      selectedIdList,
      updateSelection,
      dir,
      noDataMsg,
      adminGetList,
      changeSize,
      changeNo,
      dataSource,
      delCodeRef,
      doDelCodes,
      delOneCode,
      delMsg,
      createCodeRef,
      doAdminGetCodeList,


    }
  }

}
</script>

<style scoped>
.btn-style{
  font-size: 12px;
  height: 30px;
}

.global{
  margin: 0 15px;
  width: calc(75vw);
}

.bar {
  display: flex;
  align-items: center;
  margin: 15px 0;
  margin-bottom: 10px;
}

.iconfont{
  font-size: 13px;
}

.input-style {
  width: 25vw;
  margin: 0 8px;
  margin-left: 15px;
  font-size: 13px;
}

.body {
  font-size: 13px;
  font-weight: bold;
  margin-bottom: 5px;
}

</style>