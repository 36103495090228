<template>
  <div>
    <div v-if="dir == 'user'" style="font-weight: bold;">全部用户</div>
    <div v-else-if="dir == 'ActiveCode'" style="font-weight: bold;">全部激活码</div>
    <div v-else-if="dirArr.length == 1" style="font-weight: bold;">全部文件</div>
    <div v-else class="row-one">
      <div class="global text-too-long" >
        <div class="dir-item able" @click="changeDir(dirArr.length - 2)">返回上一级 </div>
        <div style="margin: 0 5px; color: #dddee0;"> | </div>
        <div v-for="(item, index) in dirArr" :key="index" class="dir-item" style="display: flex; align-items: center;">
          <div class="able" v-if="index != dirArr.length - 1" @click="changeDir(index)"> {{ item }} </div>
          <div v-else style="color: #797979;"> {{ item }} </div>
          <div v-if="index != dirArr.length - 1" style="margin: 0 5px;"> &gt; </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { computed } from 'vue';

export default {
  name:'DirShow',
  components: {
  },
  props:['dir'],
  setup(props,context) {

    let dirArr = computed(() => {
      let arr = [];
      arr = props.dir.split('/');
      let tmp = ['全部文件'];
      for(let i=1;i<arr.length-1;i++) tmp.push(arr[i]);
      return tmp;
    });

    // 改变目录
    const changeDir = (index) => {
      let new_dir = "/";
      for(let i=1;i<=index;i++) new_dir += dirArr.value[i] + "/";
      context.emit('changeDir',new_dir,index);
    }

    return {
      dirArr,
      changeDir,


    }
  }

}
</script>

<style scoped>
.global {
  display: flex; 
  align-items: center; 
  font-weight: lighter; 
  font-size: 12px;
}
.row-one {
  max-width: 75vw;
}
.dir-item {
  color: #05a9f8;
}

.able {
  cursor: pointer;
}

.text-too-long {
  white-space: nowrap; 
  overflow: hidden; 
  text-overflow:ellipsis;
}

</style>