<template>
  <el-dialog
    v-model="DialogVisilble"
    :before-close="closeDialog"
    title="新建目录"
    width="30%"
  >
    <div style="margin-top: -15px;"></div>
    <div class="item-class">
      <div style="display: flex; align-items: center;">
        <img src="../assets/icon-image/folder.png" alt="文件夹" style="width: 35px;">
        <div style="margin-right: 5px;"></div>
        <el-input v-model="new_dir_name" placeholder="新目录" clearable  />
      </div>
    </div>
    <div class="errInfo"> {{ err_msg }} </div>
    <div style="margin-bottom: 15px;"></div>
    <div style="display: flex; border-top: 1px solid #f1f2f4;">
      <div style="flex: 1;"></div>
      <el-button type="primary" @click="doMakeDir" style="margin-bottom: -15px; margin-top: 10px;">
        确定
      </el-button>
    </div>
  </el-dialog>
</template>

<script>
import { ref } from 'vue';

export default {
  name:'CreateDir',
  components: {

  },
  setup(props,context) {

    const err_msg = ref('');
    const new_dir_name = ref('');

    const DialogVisilble = ref(false);

    // 新建目录
    const doMakeDir = () => {
      err_msg.value = '';
      // 校验参数
      if(new_dir_name.value.length == 0) {
        err_msg.value = '新目录不能为空';
        return;
      }
      if(new_dir_name.value.indexOf('/') != -1) {
        err_msg.value = '目录名不能含有 /';
        return;
      }
      context.emit('mkDir',new_dir_name.value);
      err_msg.value = '';
      new_dir_name.value = '';
    }

    // 关闭
    const closeDialog = (done) => {
      err_msg.value = '';
      done();
    }

    return {
        err_msg,
        new_dir_name,
        doMakeDir,
        DialogVisilble,
        closeDialog,


    }
  }

}
</script>

<style scoped>
.item-class {
    font-size: 12px;
    color: #606266;
}

.text {
    margin-bottom: 20px;
}

.errInfo{
  font-size: 12px;
  color: rgb(234, 80, 80);
  margin-top: 5px;
}
</style>