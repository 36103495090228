<template>
  <div class="global">
    <div class="content">
      <div>
        <div style="display: flex; align-items: center;">
          <div style="flex: 1;"></div>
          <div><img src="../../assets/logo.png" class="logoimg" alt="kk网盘"></div>
          <div class="logofont" > kk网盘 </div>
          <div style="flex: 1;"></div>
        </div>
      </div>
      <div class="body-class">
        <div class="header">
          <div><el-avatar :src="shareInfo.user_image" /></div>
          <div style="margin-right: 8px;"></div>
          <div style="display: flex; flex-direction: column;">
            <div style="display: flex; align-items: start;">
              <!-- vip 管理员标识 -->
              <div v-if="shareInfo.user_state == 2">
                <img src="../../assets/vip.png" alt="VIP" style="width: 20px; margin-top: -2px;">
              </div>
              <div v-if="shareInfo.user_state == -1" style="">
                <img src="../../assets/管理员.png" alt="管理员" style="width: 35px; margin-top: -2px;">
              </div>
              <!-- 昵称 -->
              <div style="color: white; margin-left: 5px;"> {{ shareInfo.user_name }} </div>
              <div style="color: white; margin-left: 15px;">分享于&nbsp;{{ format(shareInfo.create_time) }}</div>
            </div>
            <div style="height: 5px;"></div>
            <div style="display: flex; color: white;">
              分享文件：{{ shareInfo.file_name }}
            </div>
          </div>
        </div>
        <div class="footer">
          <div style="padding-top: 25px; padding-bottom: 10px; padding-left: 20px; padding-right: 20px; font-weight: bold;">请输入提取码：</div>
          <div style="display: flex; align-items: center; padding-left: 20px; padding-right: 20px;">
            <el-input v-model="code" placeholder="4位提取码" clearable />
            <el-button type="primary" style="margin-left: 10px;" @click="takeFile">提取文件</el-button>
          </div>
          <div class="errInfo" style="padding-left: 20px;">{{ err_msg }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from 'vue';
import { useRoute,useRouter } from "vue-router";
import { ElMessage } from 'element-plus'
import { formatDate } from '../../Utils/FormatDate';
import $ from "jquery";
import Re from '../../Utils/VerifyRe';
import base_url from '../../Utils/BaseUrl'

export default {
  name:'ShareCheckBlock',
  components: {
  },
  setup() {

    // 时间格式化
    const format = formatDate;

    // 路由
    const router = useRouter();
    const route = useRoute();


    const shareId = ref(route.params.shareId);
    const code = ref('');
    const err_msg = ref('');
    const shareInfo = ref({});

    if(route.query.code) {
      code.value = route.query.code;
    }

    // 后端api
    const api = {
      get_shareInfo:base_url + 'webShare/getInfo/',
      take_file:base_url + 'webShare/takeFile/',
      is_get:base_url + 'webShare/isGet/',
    }

    // 获取分享信息
    const getShareInfo = () => {
      $.ajax({
        url: api.get_shareInfo,
        type:'get',
        data:{
          share_id:shareId.value,
        },
        success(resp){
          if(resp.status == 'success') {
            shareInfo.value = resp.data.shareInfo;
          } 
          else if(resp.code >= 602 && resp.code <= 606) {
            router.push({
              name:'ShareNotFound',
              query:{
                code:resp.code
              }
            });
          }
        }
      });
    }
    getShareInfo();

    // 是否提取过文件
    const isHaveGot = () => {
      $.ajax({
        url: api.is_get,
        type:'get',
        data:{
          share_id:shareId.value,
        },
        success(resp){
          if(resp.status == 'success') {
            router.push({
              name:'WebShareBlock',
              params:{
                shareId:shareId.value,
              }
            })
          }
        }
      });
    }
    isHaveGot();

    // 提取文件
    const takeFile = () => {
      err_msg.value = '';
      // 校验参数
      if(code.value == null || code.value.length != 4) {
        err_msg.value = '提取码长度必须为四位';
        return;
      }
      if(!Re.shareCode.test(code.value)) {
        err_msg.value = '提取码只能是数字或字母';
        return;
      }
      $.ajax({
        url: api.take_file,
        type:'post',
        data:{
          share_id:shareId.value,
          code:code.value,
        },
        success(resp){
          if(resp.status == 'success') {
            ElMessage({
              showClose: true,
              message: resp.info,
              type: resp.status,
            })
            router.push({
              name:'WebShareBlock',
              params:{
                shareId:shareId.value,
              }
            });
          }
          else if(resp.code >= 602 && resp.code <= 606) {
            router.push({
              name:'ShareNotFound',
              query:{
                code:resp.code
              }
            });
          }
          // 提取码错误
          else if(resp.code == 617) {
            ElMessage({
              showClose: true,
              message: resp.info,
              type: resp.status,
            })
          }
        }
      });
    }

    return {
      shareId,
      shareInfo,
      code,
      err_msg,
      getShareInfo,
      format,
      takeFile,

    }
  }

}
</script>

<style scoped>
.global {
  height: calc(100vh);
  background: url('../../assets/share_bg.png');
  background-repeat: repeat-x;
  background-position: 0 bottom;
  background-color: #eef2f6;
  display: flex;
  align-items: center;
  justify-content: center;
}

.content {
  width: 450px;
  margin-top: -50px;
}

.logoimg {
  width: 70px;
}

.logofont{
  font-size: 28px;
  font-weight: bold;
  color: #06a7ff;
  margin-left: 7px;
}

.body-class {
  box-shadow: 0 0 7px 1px #5757574f;
  border-radius: 5px;
  white-space: nowrap; 
  overflow: hidden; 
}

.header {
  height: 65px;
  font-size: 12px;
  background-color: #409eff;
  display: flex;
  align-items: center;
  padding: 0 20px;
}

.footer {
  height: 150px;
  max-height: 150px;
  background-color: #ffffff;
  font-size: 13px;
}

.errInfo{
  font-size: 12px;
  color: rgb(234, 80, 80);
  margin-top: 5px;
}

</style>