<template>
  <div class="login-body">
    <div class="login_img"></div>
    <div class="login-form">
      <div class="login_block">
        <div class="demo-input-suffix">
          <div style="font-size: large; margin-bottom: 10px;">kk网盘</div>
          <div style="margin-bottom: 15px;"></div>
          <!-- 登录表单 -->
          <div v-show="formType == 0">
            <el-input v-model="login_info.user_name" class="w-50 m-2" placeholder="请输入用户名或邮箱">
              <template #prefix>
                <el-icon class="el-input__icon"><User /></el-icon>
              </template>
            </el-input>
            <div style="margin-bottom: 15px;"></div>
            <el-input v-model="login_info.password" type="password" show-password class="w-50 m-2" placeholder="请输入密码">
              <template #prefix>
                <el-icon class="el-input__icon"><Lock /></el-icon>
              </template>
            </el-input>
            <div style="margin-bottom: 15px;"></div>
            <div style="display: flex;">
              <el-input size="large" v-model="login_info.checkCode" class="w-50 m-2" placeholder="请输入验证码">
                <template #prefix>
                  <el-icon class="el-input__icon"><EditPen /></el-icon>
                </template>
              </el-input>
              <img :src="checkCodeUrl" alt="验证码" @click="changecheckCode(0)" style="margin-left: 10px;" >
            </div>
            <div style="display: flex; align-items: center; justify-content: space-between;" >
              <el-checkbox class="a-link" v-model="isRemember">记住</el-checkbox>
              <div class="errInfo"> {{ login_info.err_msg }} </div>
            </div>
            <div class="a-link" style="float: left; margin-top: 8px;" @click="doChange(2)"> 忘记密码 </div>
            <div class="a-link" style="float: right; margin-top: 8px;" @click="doChange(1)"> 没有账号? </div>
            <div style="margin-bottom: 50px;"></div>
            <el-button type="primary" size="large" style="width: 100%;" @click="doLogin" >登录</el-button>
            <div style="display: flex; align-items: center; margin-top: 15px;">
              <div style="font-size: 14px; margin-right: 10px;">快捷登录</div>
              <div style="margin-right: 10px;"></div>
              <div style="margin-right: 30px;">
                <img @click="doQQLogin" src="../assets/qq.png" alt="QQ" style="width: 16px;">
              </div>
              <div style="margin-right: 30px;">
                <img @click="doGithubLogin" src="../assets/github.png" alt="Github" style="width: 22px;">
              </div>
              <div style="flex: 1;"></div>
            </div>
          </div>

          <!-- 注册表单 -->
          <div v-show="formType == 1">
            <el-input v-model="register_info.email" class="w-50 m-2" placeholder="请输入邮箱">
              <template #prefix>
                <el-icon class="el-input__icon"><Message /></el-icon>
              </template>
            </el-input>
            <div style="margin-bottom: 15px;"></div>
            <div style="display: flex;">
              <el-input v-model="register_info.emailCode" class="w-50 m-2" placeholder="请输入邮箱验证码">
                <template #prefix>
                  <el-icon class="el-input__icon"><EditPen /></el-icon>
                </template>
              </el-input>
              <el-button type="primary" style="margin-left: 10px;" @click="sendEmailErrMsg = '', emailDialogVisible = true" >获取验证码</el-button>
            </div>
            <div style="margin-bottom: 15px;"></div>
            <el-input v-model="register_info.user_name" class="w-50 m-2" placeholder="请输入用户名">
              <template #prefix>
                <el-icon class="el-input__icon"><User /></el-icon>
              </template>
            </el-input>
            <div style="margin-bottom: 15px;"></div>
            <el-input v-model="register_info.password" show-password class="w-50 m-2" placeholder="请输入密码 (8-18位)">
              <template #prefix>
                <el-icon class="el-input__icon"><Lock /></el-icon>
              </template>
            </el-input>
            <div style="margin-bottom: 15px;"></div>
            <el-input v-model="register_info.confirmPassword" show-password class="w-50 m-2" placeholder="确认密码">
              <template #prefix>
                <el-icon class="el-input__icon"><Lock /></el-icon>
              </template>
            </el-input>
            <div style="margin-bottom: 15px;"></div>
            <div style="display: flex;">
              <el-input size="large" v-model="register_info.checkCode" class="w-50 m-2" placeholder="请输入验证码">
                <template #prefix>
                  <el-icon class="el-input__icon"><EditPen /></el-icon>
                </template>
              </el-input>
              <img :src="checkCodeUrl" alt="验证码" @click="changecheckCode(0)" style="margin-left: 10px;" >
            </div>
            <div class="a-link" style="float: left; margin-top: 8px;" @click="doChange(0)"> 已有账号?去登录 </div>
            <div class="errInfo" style="float: right; margin-top: 8px;"> {{ register_info.err_msg }} </div>
            <div style="margin-bottom: 40px;"></div>
            <el-button type="primary" size="large" style="width: 100%;" @click="doRegister" >注册</el-button>
          </div>

          <!-- 重置密码表单 -->
          <div v-show="formType == 2">
            <el-input v-model="reset_info.email" class="w-50 m-2" placeholder="请输入邮箱">
              <template #prefix>
                <el-icon class="el-input__icon"><Message /></el-icon>
              </template>
            </el-input>
            <div style="margin-bottom: 15px;"></div>
            <div style="display: flex;">
              <el-input v-model="reset_info.emailCode" class="w-50 m-2" placeholder="请输入邮箱验证码">
                <template #prefix>
                  <el-icon class="el-input__icon"><EditPen /></el-icon>
                </template>
              </el-input>
              <el-button type="primary" style="margin-left: 10px;" @click="sendEmailErrMsg = '', emailDialogVisible = true" >获取验证码</el-button>
            </div>
            <div style="margin-bottom: 15px;"></div>
            <div style="margin-bottom: 15px;"></div>
            <el-input v-model="reset_info.password" show-password class="w-50 m-2" placeholder="请输入新密码 (8-18位)">
              <template #prefix>
                <el-icon class="el-input__icon"><Lock /></el-icon>
              </template>
            </el-input>
            <div style="margin-bottom: 15px;"></div>
            <el-input v-model="reset_info.confirmPassword" show-password class="w-50 m-2" placeholder="确认密码">
              <template #prefix>
                <el-icon class="el-input__icon"><Lock /></el-icon>
              </template>
            </el-input>
            <div style="margin-bottom: 15px;"></div>
            <div style="display: flex;">
              <el-input size="large" v-model="reset_info.checkCode" class="w-50 m-2" placeholder="请输入验证码">
                <template #prefix>
                  <el-icon class="el-input__icon"><EditPen /></el-icon>
                </template>
              </el-input>
              <img :src="checkCodeUrl" alt="验证码" @click="changecheckCode(0)" style="margin-left: 10px;" >
            </div>
            <div class="a-link" style="float: left; margin-top: 8px;" @click="doChange(0)"> 去登录? </div>
            <div class="errInfo" style="float: right; margin-top: 8px;"> {{ reset_info.err_msg }} </div>
            <div style="margin-bottom: 40px;"></div>
            <el-button type="primary" size="large" style="width: 100%;" @click="doResetPassword" >重置密码</el-button>
          </div>
          
      </div>
      </div>
    </div>
  </div>


  <!-- 发送邮件验证码 -->
  <el-dialog
    v-model="emailDialogVisible"
    :before-close="closeDialog"
    title="邮箱验证"
    width="30%"
  >
  <div style="margin-top: -15px;"></div>
    <div v-if="formType == 1">发送到邮箱: {{ register_info.email }}</div>
    <div v-if="formType == 2">发送到邮箱: {{ reset_info.email }}</div>
    <div style="margin-bottom: 25px;"></div>
    <div style="display: flex;">
      <el-input size="large" v-model="beforeSendEmailCheckCode" class="w-50 m-2" placeholder="请输入验证码">
        <template #prefix>
          <el-icon class="el-input__icon"><EditPen /></el-icon>
        </template>
      </el-input>
      <img :src="emailCheckCodeUrl" alt="验证码" @click="changecheckCode(1)" style="margin-left: 10px;" >
    </div>
    <div class="errInfo"> {{ sendEmailErrMsg }} </div>
    <div style="margin-bottom: 15px;"></div>
    <div style="display: flex; border-top: 1px solid #f1f2f4;">
      <div style="flex: 1;"></div>
      <el-button type="primary" @click="sendEmail" style="margin-bottom: -15px; margin-top: 10px;">
        发送邮箱验证码
      </el-button>
    </div>
  </el-dialog>
</template>

<script>
import { ref, reactive } from "vue";
import { ElMessage } from 'element-plus'
import Re from "../Utils/VerifyRe";
import $ from "jquery";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import resetReactive from '../Utils/ResetReactive'
import { encrypt, decrypt,Base64 } from '../Utils/DESUtil';
import base_url from '../Utils/BaseUrl'

export default {
  name:'LoginView',
  components: {

  },
  setup() {
    // vuex
    const store = useStore();
    // vue-router
    const router = useRouter();
    // 后端api
    const api = {
      getCheckCode: base_url + 'getCheckCode/',
      sendEmail:base_url + 'sendEmail/',
      register:base_url + 'register/',
      login:base_url + 'login/',
      resetPassword:base_url + 'resetPassword/',
      qq_login:base_url + 'qq/doLogin/',
      github_login:base_url + 'github/doLogin/',

    }

    // 登录表单对象
    const login_info_Raw = {
      err_msg:'',
      user_name:'',
      password:'',
      checkCode:'',
    }
    const login_info = reactive({...login_info_Raw});
    // 注册表单对象
    let emailDialogVisible = ref(false);
    let emailCheckCodeUrl = ref(api.getCheckCode + '?type=1&time=' + new Date().getTime());
    const register_info_Raw = {
      err_msg:'',
      email:'',
      user_name:'',
      password:'',
      checkCode:'',
      emailCode:'',
      confirmPassword:'',
    };
    const register_info = reactive({...register_info_Raw});
    // 重置密码表单对象
    const reset_info_Raw = {
      err_msg:'',
      email:'',
      password:'',
      checkCode:'',
      emailCode:'',
      confirmPassword:'',
    };
    const reset_info = reactive({...reset_info_Raw});

    // 记住密码
    let isRemember = ref(false)
    const rememberMe = () => {
      let userInfo = null;
      if(localStorage.getItem('userInfo')) {
        userInfo = JSON.parse(decrypt(localStorage.getItem('userInfo')));
      }
      // 记住密码未过期
      if(userInfo && userInfo.vaildTime > new Date().getTime()) {
        login_info.user_name = userInfo.user_name;
        login_info.password = userInfo.password;
        isRemember.value = true;
      }
      // 记住密码已过期
      else if(userInfo && userInfo.vaildTime <= new Date().getTime()) {
        localStorage.removeItem('userInfo');
        isRemember.value = false;
      }
    }

    // 表单切换变量，0为登录，1为注册，2为找回密码
    let formType = ref(0);
    rememberMe();
    // 切换表单
    const doChange = (type) => {
      formType.value = type;
      changecheckCode(0);
      changecheckCode(1);
      beforeSendEmailCheckCode.value = '';
      // 重置对象
      resetReactive(login_info,login_info_Raw);
      resetReactive(register_info,register_info_Raw);
      resetReactive(reset_info,reset_info_Raw);
      // 记住密码
      rememberMe();
    }



    // 刷新验证码
    let checkCodeUrl = ref(api.getCheckCode);
    const changecheckCode = (type) => {
      // new Date().getTime() 保证刷新，防止访问缓存
      if(type == 0) checkCodeUrl.value = api.getCheckCode + '?type=' + type + '&time=' + new Date().getTime();
      if(type == 1) emailCheckCodeUrl.value = api.getCheckCode + '?type=' + type + '&time=' + new Date().getTime();
    }

    // 发送邮箱验证码注册
    let beforeSendEmailCheckCode = ref('');
    let sendEmailErrMsg = ref('');
    const sendEmail = () => {
      sendEmailErrMsg.value = '';
      // 校验邮箱格式
      let email = '';
      if(formType.value == 1) email = register_info.email;
      else if(formType.value == 2) email = reset_info.email;
      if(!Re.email.test(email)) {
        sendEmailErrMsg.value = '邮箱格式不正确！';
        return;
      }
      if(!(beforeSendEmailCheckCode.value && beforeSendEmailCheckCode.value != '')) {
        sendEmailErrMsg.value = '验证码不能为空！';
        return;
      }
      $.ajax({
        url: api.sendEmail,
        type:'post',
        data:{
          email: email,
          checkCode: beforeSendEmailCheckCode.value,
          type: formType.value - 1,
        },
        success(resp){
          ElMessage({
            showClose: true,
            message: resp.info,
            type: resp.status,
          })
          if(resp.status == 'success') {
            emailDialogVisible.value = false;
            beforeSendEmailCheckCode.value = '';
          }
          changecheckCode(1);
        }
      });


    }

    // 注册
    const doRegister = () => {
      register_info.err_msg = '';
      // 校验参数
      if(!Re.email.test(register_info.email)) {
        register_info.err_msg = '邮箱格式不正确！';
        return;
      }
      if(register_info.user_name.length > 20) {
        register_info.err_msg = '用户名太长';
        return;
      }
      if(!Re.password.test(register_info.password)) {
        register_info.err_msg = '密码格式不正确';
        return;
      }
      if(register_info.confirmPassword != register_info.password) {
        register_info.err_msg = '两次输入的密码不一致';
        return;
      }
      if(!(register_info.checkCode && register_info.checkCode != '')) {
        register_info.err_msg = '验证码不能为空';
        return;
      }
      if(!(register_info.emailCode && register_info.emailCode != '')) {
        register_info.err_msg = '邮箱验证码不能为空';
        return;
      }
      $.ajax({
        url: api.register,
        type:'post',
        data:{
          email: register_info.email,
          checkCode: register_info.checkCode,
          emailCode: register_info.emailCode,
          user_name: register_info.user_name,
          user_password: Base64.encode(register_info.password),
        },
        success(resp){
          ElMessage({
            showClose: true,
            message: resp.info,
            type: resp.status,
          })
          if(resp.status == 'success') doChange(0);
          changecheckCode(0);
          changecheckCode(1);
        }
      });
    }

    // 登录
    const doLogin = () => {
      // 校验参数
      if(!(login_info.checkCode && login_info.checkCode != '')) {
        login_info.err_msg = '验证码不能为空';
        return;
      }
      if(!(login_info.user_name && login_info.user_name != '')) {
        login_info.err_msg = '用户名不能为空';
        return;
      }
      if(!(login_info.password && login_info.password != '')) {
        login_info.err_msg = '密码不能为空';
        return;
      }
      $.ajax({
        url: api.login,
        type:'post',
        data:{
          checkCode: login_info.checkCode,
          user_name: login_info.user_name,
          user_password: Base64.encode(login_info.password),
        },
        success(resp){
          ElMessage({
            showClose: true,
            message: resp.info,
            type: resp.status,
          })
          if(resp.status == 'success') {
            store.commit('updateUserInfo',resp.data.userInfo);
            if(isRemember.value == true) {
              localStorage.setItem('userInfo',encrypt(JSON.stringify({
                user_name: login_info.user_name,
                password: login_info.password,
                vaildTime: new Date().getTime() + 7 * 24 * 60 * 60 * 1000,
              })));
            }
            else localStorage.removeItem('userInfo');
            localStorage.setItem('pantoken',resp.data.pantoken);
            changecheckCode(0);
            changecheckCode(1);
            if(store.state.aim_path && store.state.aim_path != '') {
              router.push(store.state.aim_path);
              store.state.aim_path = '';
            }
            else router.push('/home/all');
          }
          changecheckCode(0);
          changecheckCode(1);
        }
      });
    }

    // 重置密码
    const doResetPassword = () => {
      // 校验参数
      reset_info.err_msg = '';
      // 校验参数
      if(!Re.email.test(reset_info.email)) {
        reset_info.err_msg = '邮箱格式不正确！';
        return;
      }
      if(!Re.password.test(reset_info.password)) {
        reset_info.err_msg = '密码格式不正确';
        return;
      }
      if(reset_info.confirmPassword != reset_info.password) {
        reset_info.err_msg = '两次输入的密码不一致';
        return;
      }
      if(!(reset_info.checkCode && reset_info.checkCode != '')) {
        reset_info.err_msg = '验证码不能为空';
        return;
      }
      if(!(reset_info.emailCode && reset_info.emailCode != '')) {
        reset_info.err_msg = '邮箱验证码不能为空';
        return;
      }
      $.ajax({
        url: api.resetPassword,
        type:'post',
        data:{
          email: reset_info.email,
          checkCode: reset_info.checkCode,
          emailCode: reset_info.emailCode,
          user_password: Base64.encode(reset_info.password),
        },
        success(resp){
          ElMessage({
            showClose: true,
            message: resp.info,
            type: resp.status,
          });
          if(resp.status == 'success') {
            localStorage.removeItem('userInfo');
            localStorage.removeItem('pantoken');
            doChange(0);
          }
          changecheckCode(0);
          changecheckCode(1);
        }
      });
    }

    // QQ快捷登录
    const doQQLogin = () => {
      $.ajax({
        url: api.qq_login,
        type:'get',
        data:{
          callbackUrl:'/',
        },
        success(resp){
          if(resp.status != 'success') {
            ElMessage({
              showClose: true,
              message: resp.info,
              type: resp.status,
            })
          }
          if(resp.status == 'success') {
            window.location.href = resp.data.url;
          }
        }
      });
    }

    // Github快捷登录
    const doGithubLogin = () => {
      $.ajax({
        url: api.github_login,
        type:'get',
        data:{
          callbackUrl:'/',
        },
        success(resp){
          if(resp.status != 'success') {
            ElMessage({
              showClose: true,
              message: resp.info,
              type: resp.status,
            })
          }
          if(resp.status == 'success') {
            window.location.href = resp.data.url;
          }
        }
      });
    }

    // 关闭
    const closeDialog = (done) => {
      sendEmailErrMsg.value = '';
      beforeSendEmailCheckCode.value = '';
      done();
    }


    return {
      emailDialogVisible,
      formType,
      login_info,
      register_info,
      reset_info,
      checkCodeUrl,
      beforeSendEmailCheckCode,
      changecheckCode,
      sendEmailErrMsg,
      sendEmail,
      emailCheckCodeUrl,
      doRegister,
      doLogin,
      doResetPassword,
      doChange,
      rememberMe,
      isRemember,
      closeDialog,
      doQQLogin,
      doGithubLogin,

    }
  }

}
</script>



<style scoped>
.login-body{
  height: 100vh;
  background-image: url(../assets/login_bg.jpg);
  background-size: cover;
  display: flex;
}
.login_img {
  flex: 2;
  background-position: center;
  background-size: 500px;
  background-repeat: no-repeat;
  background-image: url("../assets/login_img.png");
}

.login-form{
  flex: 1;
  padding: 5% 10%;
}

.login_block{
  background-color: white;
  text-align: center;
  padding: 20px 20px;
  border-radius: 5px;
}

img:hover{
  cursor: pointer;
}

.errInfo{
  font-size: 12px;
  color: rgb(234, 80, 80);
  margin-top: 5px;
}

</style>