<template>
  <el-dialog
    v-model="DialogVisilble"
    :before-close="closeDialog"
    title="激活码"
    width="30%"
  >
    <div style="margin-top: -15px;"></div>
    <div class="item-class">
      <el-input v-model="activeCode" class="w-50 m-2" placeholder="请输入激活码">
        <template #prefix>
          <img src="../assets/激活码.png" alt="激活码" style="width: 20px;">
        </template>
      </el-input>
    </div>
    <div class="errInfo"> {{ err_msg }} </div>
    <div style="margin-bottom: 15px;"></div>
    <div style="display: flex; border-top: 1px solid #f1f2f4;">
      <div style="flex: 1;"></div>
      <el-button type="primary" @click="doActive" style="margin-bottom: -15px; margin-top: 10px;">
        确定
      </el-button>
    </div>
  </el-dialog>
</template>

<script>
import { ref } from 'vue';
import { ElMessage } from 'element-plus'
import $ from "jquery";
import { gotoLogin } from '../Utils/gotoLogin';
import { useRouter } from "vue-router";
import base_url from '../Utils/BaseUrl'

export default {
  name:'ActiveCode',
  components: {
  },
  setup() {

    // vue-router
    const router = useRouter();

    // 后端api
    const api = {
      do_active: base_url + 'modify/activeCode/',
    }

    // 控制Dialog显示
    const DialogVisilble = ref(false);

    // 使用激活码
    const activeCode = ref('');
    const err_msg = ref('');
    const doActive = () => {
      // 校验参数
      err_msg.value = '';
      if(!(activeCode.value && activeCode.value != '')) {
          err_msg.value = '激活码不能为空';
          return;
      }
      $.ajax({
        url: api.do_active,
        type:'post',
        data: {
          activeCode:activeCode.value,
        },
        headers: {
          pantoken: localStorage.getItem('pantoken'),
        },
        success(resp){
          ElMessage({
            showClose: true,
            message: resp.info,
            type: resp.status,
          })
          if(resp.status == 'success') {
            activeCode.value = '';
            DialogVisilble.value = false;
            err_msg.value = '';
          }
          gotoLogin(router,resp);
        }
      });
    }

    // 关闭
    const closeDialog = (done) => {
      err_msg.value = '';
      activeCode.value = '';
      done();
    }

    return {
      DialogVisilble,
      activeCode,
      err_msg,
      doActive,
      closeDialog,

    }
  }


}
</script>

<style scoped>
.errInfo{
  font-size: 12px;
  color: rgb(234, 80, 80);
  margin-top: 5px;
}
</style>