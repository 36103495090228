<template>
  <el-dialog
    v-model="DialogVisilble"
    :before-close="closeDialog"
    title="修改密码"
    width="30%"
  >
    <div style="margin-top: -15px;"></div>
    <div class="item-class">
      <el-input v-model="new_password" show-password class="w-50 m-2" placeholder="请输入新密码 (8-18位)">
        <template #prefix>
          <el-icon class="el-input__icon"><Lock /></el-icon>
        </template>
      </el-input>
      <div style="margin-bottom: 15px;"></div>
      <el-input v-model="confirm_password" show-password class="w-50 m-2" placeholder="确认密码">
        <template #prefix>
          <el-icon class="el-input__icon"><Lock /></el-icon>
        </template>
      </el-input>
    </div>
    <div class="errInfo"> {{ err_msg }} </div>
    <div style="margin-bottom: 15px;"></div>
    <div style="display: flex; border-top: 1px solid #f1f2f4;">
      <div style="flex: 1;"></div>
      <el-button type="primary" @click="doModifyPassword" style="margin-bottom: -15px; margin-top: 10px;">
        确定
      </el-button>
    </div>
  </el-dialog>
</template>

<script>
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import { ref } from 'vue';
import { ElMessage } from 'element-plus'
import $ from "jquery";
import { Base64 } from "../Utils/DESUtil";
import Re from "../Utils/VerifyRe";
import { gotoLogin } from '../Utils/gotoLogin';
import base_url from '../Utils/BaseUrl'

export default {
  name:'ModifyPassword',
  components: {
  },
  setup() {
    // vuex
    const store = useStore();

    // vue-router
    const router = useRouter();

    // 后端api
    const api = {
      modify: base_url + 'modify/password/',
    }

    // 控制Dialog显示
    const DialogVisilble = ref(false);

    // 密码
    const new_password = ref('');
    const confirm_password = ref('');
    const err_msg = ref('');

    // 修改密码
    const doModifyPassword = () => {
      err_msg.value = '';
      // 校验参数
      if(!Re.password.test(new_password.value)) {
        err_msg.value = '密码格式不正确';
        return;
      }
      if(new_password.value != confirm_password.value) {
        err_msg.value = '两次输入的密码不一致';
        return;
      }
      $.ajax({
        url: api.modify,
        type:'post',
        data: {
          new_password: Base64.encode(new_password.value),
        },
        headers: {
          pantoken: localStorage.getItem('pantoken'),
        },
        success(resp){
          ElMessage({
            showClose: true,
            message: resp.info,
            type: resp.status,
          })
          if(resp.status == 'success') {
            DialogVisilble.value = false;
            localStorage.removeItem('userInfo');
            localStorage.removeItem('pantoken');
            router.push({
              name:'LoginView'
            })
          }
          gotoLogin(router,resp);
        }
      });
    }

    // 关闭
    const closeDialog = (done) => {
      err_msg.value = '';
      new_password.value = '';
      confirm_password.value = '';
      done();
    }

    return {
      store,
      DialogVisilble,
      new_password,
      confirm_password,
      err_msg,
      doModifyPassword,
      closeDialog,
    
    }
  }

}
</script>

<style scoped>
  
.item-class {
  font-size: 13px;
  color: #606266;
}

.errInfo{
  font-size: 12px;
  color: rgb(234, 80, 80);
  margin-top: 5px;
}
  
  
</style>