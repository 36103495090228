import { createStore } from 'vuex'
import $ from "jquery";
import base_url from '@/Utils/BaseUrl';

export default createStore({
  state: {
    userInfo:{
      user_id:'',
      user_email:'',
      user_name:'',
      qq_name:'',
      github_name:'',
      user_image:'',
      last_login_time:'',
      user_state:1,
      use_space:0,
      total_space:1024 * 1024 * 1024,
    },
    is_login: false,
    aim_pathName:'',
  },
  getters: {

  },
  mutations: {
    updateUserInfo(state, data) {
      state.userInfo = data;
      state.is_login = true;
    },
    updateImage(state, url) {
      state.userInfo.user_image = url;
    },
    updateSpace(state, data) {
      state.userInfo.use_space = data.use_space;
      state.userInfo.total_space = data.total_space;
    },
    

  },
  actions: {
    doLoginByToken(context,success) {
      $.ajax({
        url: base_url + 'token/login/',
        type:'post',
        data:{},
        headers: {
          pantoken: localStorage.getItem('pantoken'),
        },
        success(resp){
          if(resp.status == 'success') context.commit('updateUserInfo',resp.data.userInfo);
          success(resp);
        }
      });
    },
    doUpdateSpaceByToken(context,success) {
      $.ajax({
        url: base_url + 'token/getSpace/',
        type:'get',
        data:{},
        headers: {
          pantoken: localStorage.getItem('pantoken'),
        },
        success(resp){
          if(resp.status == 'success') context.commit('updateSpace',resp.data);
          success(resp);
        }
      });
    }

  },
  modules: {

  }
})
